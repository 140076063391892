import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PopupComponent from "../util/SmartKeyPopup";
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import Filter from '../util/Filter';
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var visitData = '';
var clear = false;
var name = '';
var phone = '';

var filterMode = {
    name : true,
    phone : true
}

let storage = {
    nameKeyword : '',
    phoneKeyword : '',
    popupMode : '',
    popupData : {}
}

function Smartkey() {
    const [member, setMember] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function openPopup(mode, data) {
        setShowPopup(true);
        storage.popupMode = mode;
        storage.popupData = data;
    }

    function closePopup(isUpdated) {
        setShowPopup(false);
        if(isUpdated === "updated"){
            activePage = 1;
            getMember()
        }
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleClear(){
        storage.nameKeyword = '';
        storage.phoneKeyword = '';
        clear = true;
        activePage = 1;
        getMember();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        storage.phoneKeyword = filterData.phoneKeyword
        activePage = 1;
        getMember();
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(visitData,page,pageSize);
        
        totalCount = visitData.length;

        setMember(pageCurrent.data);
    }

    
    
    function getMember(){
        if(clear === true){
            name = ''
            phone = '';
        }else{
            name = storage.nameKeyword;
            phone = storage.phoneKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/member",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                phoneKeyword : phone
            }
        }).then(function (res){
            visitData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(visitData,page,pageSize);
            
            totalCount = visitData.length;

            setMember(pageCurrent.data)
            clear = false;
        }).catch(function(error){
            console.log(error);
        })
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getMember();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getMember();
            activePage = 1;
        }
    },[selectedCenter]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>스마트키</h3>
            <div className="content-component">
                <div className="content-header">
                <Button className="add-button" onClick={() => openPopup("add", {})}><VpnKeyIcon /> &nbsp; 키 추가 </Button>
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
                </div>
                <div className="content-body">
                    <div className="table-header">
                        <div></div>
                        <div style={{display : "inline-flex"}}>
                        <Filter 
                            handleFilter = {handleFilter} 
                            handleClear = {handleClear}
                            mode = {filterMode}
                        />
                        </div>
                    </div>
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'150px'}}>센터명</th>
                                <th style={{width:'80px'}}>이름</th>
                                <th style={{width:'140px'}}>연락처</th>
                                <th style={{width:'200px'}}>시작일</th>
                                <th style={{width:'200px'}}>종료일</th>
                                <th style={{width:'80px'}}>상태</th>
                                <th style={{width:'150px'}}>상품명</th>
                                <th style={{width:'200px'}}>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {member.map(( member, index ) => {
                            const { keycode, regdate, sdate, edate, state, name, phone, center, location } = member;
                            const stateClass = (state === "Y") ? 'open' : 'closed';
                            return(
                                <tr key={index} onClick={() => openPopup("edit", member)} >
                                    <td>{center + " " +location}</td>
                                    <td>{name}</td>
                                    <td>{phone}</td>
                                    <td>{sdate}</td>
                                    <td>{edate}</td>
                                    <td><FontAwesomeIcon icon={faKey} className={stateClass} /></td>
                                    <td>{keycode}</td>
                                    <td>{regdate}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            {showPopup ? 
            <PopupComponent
                data = {storage.popupData}
                mode = {storage.popupMode}
                closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}

export default Smartkey;