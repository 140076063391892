import axios from 'axios';

let access_token = ' ';
let result = '';

export default async function DecodeData(url){
    access_token = localStorage.getItem('access_token');

    try {
      result = await axios({
          url : url + "/slim/token/decode",
          method : "post",
          data : {
            sid : "smartgym",
            access_token : access_token
          }
      })
      return result.data;
  } catch (error) {
    throw error;
  } 
  
    
}