import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select';
import '../components/Main.css';
import { useSelector } from 'react-redux';
  
var centerOption = [{value : '', label : "전체"}];

export default function SelectCenterOption({onSelectedCenterOption}) {
    const [selectCenterOption, setSelectCenterOption] = useState([{value : '', label : "전체"}]);
    const data = useSelector(state => state.data);

    function getCenterOptionList(){
        axios({
            url : data.url + "/slim/center_option_list",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(res => {
            res.data.map((list)=> {
                const { centerOptionValue } = list;
                centerOption.push({ value : centerOptionValue, label : centerOptionValue})
            });
        })
    }

    function handleSelectChange(selectedOption){
        setSelectCenterOption(selectedOption);
        onSelectedCenterOption(selectedOption.value)
    }

    useEffect(()=>{
        getCenterOptionList();
    },[]);

    return (
        <div className="select-container">
            <div className={"select-center mb-0"}>
                <Select 
                    placeholder="센터 종류"
                    value={selectCenterOption}
                    onChange={handleSelectChange}
                    options = {centerOption}
                />
            </div>
        </div>
    )
}