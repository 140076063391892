import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import { faKey, faLightbulb, faPowerOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RedeemIcon from '@material-ui/icons/Redeem';
import PopupComponent from "../util/ItemPopup";
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var itemData = '';

let storage = {
    popupMode : '',
    popupData : {}
}

function Item() {
    const [item, setItem] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function openPopup(mode, data) {
        setShowPopup(true);
        storage.popupMode = mode;
        storage.popupData = data;
    }

    function closePopup(isUpdated) {
        setShowPopup(false);
        if(isUpdated === "updated"){
            activePage = 1;
            getItem()
        }
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(itemData,page,pageSize);
        
        totalCount = itemData.length;
        console.log('totalCount : '+ totalCount);
        setItem(pageCurrent.data);
    }
    
    function getItem(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        console.log(temp_cid);

        axios({
            url : data.url + "/slim/item",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            itemData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(itemData,page,pageSize);

            totalCount = itemData.length;
            setItem(pageCurrent.data)
        }).catch(function(error){
            console.log(error);
        })
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getItem();
            setLoaded(1);
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getItem();
            activePage = 1;
        }
    },[selectedCenter]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>상품 관리</h3>
            <div className="content-component">
                <div className="content-header">
                <Button className="add-button" onClick={() => openPopup("add", {})}><RedeemIcon /> &nbsp; 상품 추가 </Button>
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
                </div>
                <div className="content-body">
                    <div className="table-header">
                    </div>
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'180px'}}>센터명</th>
                                <th style={{width:'200px'}}>구분</th>
                                <th style={{width:'280px'}}>상세정보</th>
                                <th style={{width:'200px'}}>기간</th>
                                <th style={{width:'250px'}}>가격</th>
                                <th style={{width:'90px'}}>노출</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0 && loaded === 1) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {item.map(( i, index ) => {
                            const { center, location, gubun, detail, priod, unit, price, state } = i;
                            const stateClass = (state === "1") ? 'closed' : 'open';
                            const priodUnit = (unit === '1') ? '일' : '개월';
                            return(
                                <tr key={index} onClick={() => openPopup("edit", i)} >
                                    <td>{center + " " +location}</td>
                                    <td>{gubun}</td>
                                    <td>{detail}</td>
                                    <td>{priod + priodUnit}</td>
                                    <td>{price}</td>
                                    <td><FontAwesomeIcon icon={faLightbulb} className={stateClass} /></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            {showPopup ? 
            <PopupComponent
                data = {storage.popupData}
                mode = {storage.popupMode}
                closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}

export default Item;