import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

var totalCount = 0; 

function SearchItemPopup(props) {
    const [itemInfo, setItemInfo] = useState([]);
    const data = useSelector(state => state.data);

    function getItem(){
        axios({
            url : data.url + "/slim/item_popup",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : props.cid
            }
        }).then(function (response){
            totalCount = response.data.length;
            setItemInfo(response.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClick(itemInfo){
        props.closePopup();
        props.onSubmit(itemInfo);
    }

    useEffect(()=>{
        getItem();
    },[]);

    return(
        <div className="popup item">
            <div className="searchPopupInner">
            <p>상품 검색</p>
            <FontAwesomeIcon icon={faTimes} onClick={props.closePopup} className="closeIcon" />
            <div className="search-popup-container">
            </div>
            <Table hover className="scrolltbody">
                <thead>
                <tr>
                    <th>구분</th>
                    <th>종목</th>
                    <th>가격</th>
                </tr>
                </thead>
                <tbody>
                {(totalCount === 0) &&
                        <tr>
                            <td colSpan='20' style={{width:'540px'}}>
                                데이터가 없습니다.
                            </td>
                        </tr>
                }
                {itemInfo.map((itemInfo, index) => {
                    const { icd, group, itemName, price1} = itemInfo;
                    return(
                        <tr key={index} onClick={()=>handleClick(itemInfo)}>
                            <td>{group}</td>
                            <td>{itemName}</td>
                            <td>{price1}</td>
                        </tr>
                    )
                })}
                {Number(totalCount) > 100 &&
                    <tr>
                    <td colSpan="20" className="bg-light text-center">...</td>
                    </tr>
                }
                </tbody>
            </Table>
            </div>
        </div>
    )
}

export default SearchItemPopup;
