import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import { format } from 'date-fns';
import PopupComponent from "../util/SmartKeyPopup";
import ButtonStyle from "../util/ButtonStyle";
import CheckBoxBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector, useDispatch } from 'react-redux';

var pathOption = [];
var aimOption = [];

let storage = {
    clientVisitCount : 0,
    clientSmartkeyCount : 0,
    popupMode : '',
    popupData : {}
}

let initialState = {
    center : '',
    location : '',
    name : '',
    formatBirthday : '',
    gender : ''
}

export default function ClientInfo({match}) {
    const mcd = match.params.mcd;
    const [personInfo, setPersonInfo] = useState(initialState);
    const [clientVisit, setClientVisit] = useState([]);
    const [clientSmartkey, setClientSmartkey] = useState([]);
    const [clientDevice, setClientDevice] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    function getClientPersonInfo(){
        console.log()
        axios({
            url : data.url + "/slim/client_person_info",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            var temp = res.data[0];

            if(res.data[0].gender === "1"){
                temp["gender"] = "남";
            }else if(res.data[0].gender === "2"){
                temp["gender"] = "여";
            }else {
                temp["gender"] = "미입력";
            }

            var year = res.data[0].birthday.substring(0, 4);
            var month = res.data[0].birthday.substring(4, 6);
            var day = res.data[0].birthday.substring(6, 8);
            temp["formatBirthday"] = year + '-' + month + "-" + day;

            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,7);
            var num3 = res.data[0].phone.substring(7,11);
            temp["formatPhone"] = num1 + '-' + num2 + "-" + num3;
            
            if(res.data[0].path){
                console.log(res.data[0].path);
                temp["findPath"] = pathOption.find(element => element.value === res.data[0].path).label;
            }
            if(res.data[0].aim){
                console.log(res.data[0].aim);
                temp["findAim"] = aimOption.find(element => element.value === res.data[0].aim).label
            }

            setPersonInfo(temp);
            getClientDevice(temp.phone);
        }).catch(function(error){
            console.log(error);
        })
    }

    async function getPathOption(){
        try {
            let result = await axios({
                url : data.url + "/slim/path_option",
                method : "post",
                data : {
                    sid : 'smartgym'
                }
            })
            result.data.map((options) => {
                pathOption.push({ value : options.value , label : options.label})
            });
            return "Y";
        } catch (error) {
          return "N";
        } 
    }

    async function getAimOption(){
        try {
            let result = await axios({
                url : data.url + "/slim/aim_option",
                method : "post",
                data : {
                    sid : 'smartgym'
                }
            })
            result.data.map((options) => {
                aimOption.push({value : options.value , label : options.label})
            });
            return "Y";
        } catch (error) {
          return "N";
        } 
    }

    function getClientVisit(){
        axios({
            url : data.url + "/slim/client_visit",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            setClientVisit(res.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    function getClientVisitCount(){
        axios({
            url : data.url + "/slim/client_visit_count",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            storage.clientVisitCount = res.data[0].count;
            if(res.data.length === 0){
                storage.clientVisitCount = 0;
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function getClientSmartkey(){
        axios({
            url : data.url + "/slim/client_smartkey",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            setClientSmartkey(res.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    function getClientSmartkeyCount(){
        axios({
            url : data.url + "/slim/client_smartkey_count",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            storage.clientSmartkeyCount = res.data[0].count;
            if(res.data.length === 0){
                storage.clientSmartkeyCount = 0;
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function deleteClient(){
        axios({
            url : data.url + "/slim/delete_client_info",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            window.location.href = '/client';
        }).catch(function (error){
            console.log(error);
        })
    }

    function getClientDevice(phone){
        axios({
            url : data.url + "/slim/client_device",
            method : "post",
            data : {
                sid : 'smartgym',
                phone : phone
            }
        }).then(function (res){
            setClientDevice(res.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    function openPopup(mode, data) {
        storage.popupMode = mode;
        storage.popupData = data;
        setShowPopup(true);
    }

    function closePopup(isUpdated) {
        setShowPopup(false);
        if(isUpdated === "updated"){
            getClientSmartkeyCount();
            getClientSmartkey();
        }
    }

    function moveList(){
        window.location.href = '/client';
    }

    function moveEdit(){
        window.location.href = '/clientInfoEdit/'+mcd;
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: personInfo.name + "님의 정보를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteClient()
              },
              {
                label: 'No'
              }
            ]
          });
    }

    async function main() {
        let result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            console.log(decodeResult);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            let result1 = await getPathOption();
            if(result1 === "Y"){
                let result2 = await getAimOption();
                if(result2 === "Y"){
                    getClientPersonInfo();
                    getClientVisitCount();
                    getClientVisit();
                    getClientSmartkeyCount();
                    getClientSmartkey();
                }
            }
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <div className="content-component" style={{border:"none"}}>
                <div className="content-header">
                </div>
                <div className="content-body client-info">
                <div className="info-table-left text-left">
                <h5>상세정보</h5>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>{personInfo.center +" "+ personInfo.location}</td>
                        </tr>
                        <tr>
                            <td>고객명</td>
                            <td>{personInfo.name}</td>
                        </tr>
                        <tr>
                            <td>생년월일</td>
                            <td>{personInfo.formatBirthday + " (" +personInfo.gender+ ")"}</td>
                        </tr>
                        <tr>
                            <td>휴대전화</td>
                            <td>{personInfo.formatPhone}</td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>{personInfo.address}</td>
                        </tr>	
                        <tr>
                            <td>고객 방문경로</td>
                            <td>{personInfo.findPath}</td>
                        </tr>
                        <tr>
                            <td>운동목적</td>
                            <td>{personInfo.findAim}</td>
                        </tr>
                        <tr>
                            <td>마케팅 수신 동의</td>
                            <td>
                                {personInfo.sms === "1" ?
                                    <CheckBoxIcon className="checked"/>
                                    : <CheckBoxBlankIcon  />
                                }
                                SMS&nbsp;
                                {personInfo.push === "1" ?
                                    <CheckBoxIcon className="checked"/>
                                    : <CheckBoxBlankIcon />
                                }
                                PUSH
                            </td>
                        </tr>
                        <tr>
                            <td>회원메모</td>
                            <td>
                                <div className="memo">
                                    {personInfo.memo}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>가입일자</td>
                            <td>
                                {personInfo.regdate}
                            </td>
                        </tr>
                        <tr>
                            <td>수정일자</td>
                            <td>
                                {personInfo.update}
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="list" title="목록" buttonClick={() => moveList()}/>
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => moveEdit()}/>
                    <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
                </div>
                </div>
                <div className="info-table-right">
                    <div className="info-table-right-top">
                        <h5 style={{display : "inline-block"}}>방문 기록</h5>
                        <span style={{float : "right"}}>총 방문 횟수 : {storage.clientVisitCount}</span>
                        <div className="table-conatiner">
                        <Table striped bordered hover>
                            <thead>
                                    <tr>
                                        <th style={{width:'200px'}}>출입일자</th>
                                        <th style={{width:'180px'}}>센터명</th>
                                        <th style={{width:'100px'}}>장치번호</th>
                                        <th style={{width:'120px'}}>비고</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(clientVisit.length === 0) &&
                                        <tr>
                                            <td style={{width:'600px'}}>
                                                데이터가 없습니다.
                                            </td>
                                        </tr>
                                } 
                                {clientVisit.map(( clientVisit, index ) => {
                                    const { regdate, doorId, center, location } = clientVisit;
                                    return(
                                        <tr key={index} >
                                            <td style={{width:'200px'}}>{regdate}</td>
                                            <td style={{width:'180px'}}>{center + " " +location}</td>
                                            <td style={{width:'100px'}}>{doorId}</td>
                                            <td style={{width:'120px'}}></td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className="info-table-right-down">
                        <h5>스마트키 발급내역</h5>
                        <ButtonStyle mode="add" title="+ 키 추가" buttonClick={() => openPopup("addClient", personInfo)}/>
                        <span style={{float : "right"}}>총 발급 횟수 : {storage.clientSmartkeyCount}</span>
                        <div className="table-conatiner">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{width:'160px'}}>센터명</th>
                                    <th style={{width:'130px'}}>시작일</th>
                                    <th style={{width:'130px'}}>종료일</th>
                                    <th style={{width:'60px'}}>상태</th>
                                    <th style={{width:'130px'}}>등록일</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(clientSmartkey.length === 0) &&
                                    <tr>
                                        <td style={{width:'600px'}}>
                                            데이터가 없습니다.
                                        </td>
                                    </tr>
                            } 
                            {clientSmartkey.map(( clientSmartkey, index ) => {
                                const { center, location, sdate, edate, state, regdate } = clientSmartkey;
                                // var formatSdate = format(new Date(sdate), 'yyyy-MM-dd')
                                var formatSdate = sdate.substring(0,10);
                                // var formatEdate = format(new Date(edate), 'yyyy-MM-dd')
                                var formatEdate = edate.substring(0,10);
                                // var formatRegdate = format(new Date(regdate), 'yyyy-MM-dd')
                                var formatRegdate = regdate.substring(0,10);
                                return(
                                    <tr key={index} onClick={() => openPopup("edit", clientSmartkey)}>
                                        <td style={{width:'160px'}}>{center + " " +location}</td>
                                        <td style={{width:'130px'}}>{formatSdate}</td>
                                        <td style={{width:'130px'}}>{formatEdate}</td>
                                        <td style={{width:'60px'}}>{state}</td>
                                        <td style={{width:'130px'}}>{formatRegdate}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                    <div className="info-table-right-down">
                        <h5>로그인 시도</h5>
                        <div className="table-conatiner">
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th style={{width:'150px'}}>센터명</th>
                                    <th style={{width:'200px'}}>접속일</th>
                                    <th style={{width:'260px'}}>장치명</th>
                                </tr>
                            </thead>
                            <tbody>
                            {(clientDevice.length === 0) &&
                                    <tr>
                                        <td style={{width:'600px'}}>
                                            데이터가 없습니다.
                                        </td>
                                    </tr>
                            } 
                            {clientDevice.map(( clientDevice, index ) => {
                                const { center, location, regdate, device } = clientDevice;
                                return(
                                    <tr key={index}>
                                        <td style={{width:'150px'}}>{center + " " +location}</td>
                                        <td style={{width:'200px'}}>{regdate}</td>
                                        <td style={{width:'260px'}}>{device}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
            {showPopup ? 
            <PopupComponent
                data = {storage.popupData}
                mode = {storage.popupMode}
                closePopup={closePopup}
            />
            : null
            }
        </main>
    )
}