import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table} from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import Filter from '../util/Filter';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var clientData = '';
var clear = false;
var name = '';
var phone = '';
var filterMode = {
    name : true,
    phone : true
}

let storage = {
    nameKeyword : '',
    phoneKeyword : '',
    clientCount : 0
}
let phoneIcon = false;

export default function Client() {
    const [client, setClient] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleClear(){
        storage.nameKeyword = '';
        storage.phoneKeyword = '';
        clear = true;
        activePage = 1;
        getClient();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        storage.phoneKeyword = filterData.phoneKeyword
        activePage = 1;
        getClient();
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(clientData,page,pageSize);
        
        totalCount = clientData.length;

        setClient(pageCurrent.data);
    }

    function getClient(){
        if(clear === true){
            name = ''
            phone = '';
        }else{
            name = storage.nameKeyword;
            phone = storage.phoneKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/client",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                phoneKeyword : phone
            }
        }).then(function (res){
            clientData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(clientData,page,pageSize);
            
            totalCount = clientData.length;

            setClient(pageCurrent.data)
            clear = false;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getClientCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/client_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
            }
        }).then(function (res){
            storage.clientCount = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClick(mcd){
        window.location.href = '/clientInfo/'+mcd;
    }

    function handleAdd(){
        window.location.href = '/clientInfoAdd'
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            console.log(decodeResult);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getClientCount();
            getClient();
            activePage = 1;
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();   
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getClientCount();
            getClient();
            activePage = 1;
        }
    },[selectedCenter]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3 style={{display : "inline-block"}}>고객 관리</h3>
            <span style={{float : "right", paddingTop:"10px"}}>총 고객 수 : {storage.clientCount}</span>
            <div className="content-component">
                <div className="content-header">
                <Button className="add-button" onClick={() => handleAdd()}><PersonAddIcon /> &nbsp; 고객 추가 </Button>
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
                </div>
                <div className="content-body">
                    <div className="table-header">
                        <div></div>
                        <div style={{display : "inline-flex"}}>
                        <Filter 
                            handleFilter = {handleFilter}   
                            handleClear = {handleClear}
                            mode = {filterMode}
                        />
                        </div>
                    </div>
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'100px'}}>이름</th>
                                <th style={{width:'150px'}}>연락처</th>
                                <th style={{width:'150px'}}>가맹점</th>
                                <th style={{width:'140px'}}>생년월일</th>
                                <th style={{width:'280px'}}>주소</th>
                                <th style={{width:'200px'}}>스마트키</th>
                                <th style={{width:'180px'}}>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0 && loaded === 1 ) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {client.map(( client, index ) => {
                            const { mcd, name, phone, center, location, birthday, address, validated, regdate } = client;
                            let validKey= '';
                            if(validated != null) {
                                validKey = validated
                            }

                            var year = birthday.substring(0, 4);
                            var month = birthday.substring(4, 6);
                            var day = birthday.substring(6, 8);
                            var formatBirthday = year + '-' + month + "-" + day;
                            // var formatRegdate = format(regdate, 'yyyy-MM-dd HH:mm')
                            var formatRegdate = regdate.substring(0,16);

                            var code = mcd.substring(0,3);
                            if(code === "APP"){
                                phoneIcon = true;
                            }else{
                                phoneIcon = false;
                            }
                            return(
                                <tr key={index} onClick={() => handleClick(mcd)} >
                                    <td className="td-icon">
                                        {
                                            phoneIcon ?
                                            <PhoneIphoneIcon/>
                                            :
                                            null
                                        }
                                        {name}</td>
                                    <td>{phone}</td>
                                    <td>{center + " " +location}</td>
                                    <td>{formatBirthday}</td>
                                    <td className="td-address">{address}</td>
                                    <td>
                                        {validKey}
                                    </td>
                                    <td className="td-regdate">{formatRegdate}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}