import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Button } from 'react-bootstrap';
import './Main.css';
import './Home.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import HomeCard from '../util/HomeCard';
import BillboardChart from "react-billboardjs";
import "react-billboardjs/lib/billboard.css";
import { faAngleRight, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectCenter from "../util/SelectCenter";
import Moment from 'react-moment';
import { subDays } from 'date-fns';
import { format } from 'date-fns';
import ko from 'date-fns/locale/ko';
import { useSelector, useDispatch } from 'react-redux';

var tempDate = new Date();
var now = new Date();
var sub =0;
var countData = {
    today : 0,
    week : 0,
    month : 0,
    year : 0
}
var dateArray = [""];
var monthArray = [""];
var monthLineArray = [""];
var ch01Array = [""];
var ch02Array = [""];
var ch03Array = [""];
var ch04Array = [""];
var ch05Array = [""];
var ch06Array = [""];
var ch07Array = [""];
var ch08Array = [""];
var ch09Array = [""];
var ch10Array = [""];

var WEEK_AXIS = {
    x: {
      type: "category",
      categories: [
        format(subDays(tempDate, sub+6), 'MM.dd ') + format(subDays(tempDate, sub+6), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+5), 'MM.dd ') + format(subDays(tempDate, sub+5), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+4), 'MM.dd ') + format(subDays(tempDate, sub+4), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+3), 'MM.dd ') + format(subDays(tempDate, sub+3), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+2), 'MM.dd ') + format(subDays(tempDate, sub+2), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+1), 'MM.dd ') + format(subDays(tempDate, sub+1), 'iiii', {locale: ko}),
        format(subDays(tempDate, sub+0), 'MM.dd ') + format(subDays(tempDate, sub), 'iiii', {locale: ko})
      ]
    },
    y : {
        min : 0
    }
}

let WEEK_CHART_DATA = {
    columns: [
      dateArray
    ],
    type: "spline"
  };

var MONTH_CHART_DATA = {
    x: "x",
    xFormat: '%Y-%m-%d',  
    columns: [
      ["x", 
      format(subDays(now, 27), 'yyyy-MM-dd'),
      format(subDays(now, 26), 'yyyy-MM-dd'),
      format(subDays(now, 25), 'yyyy-MM-dd'),
      format(subDays(now, 24), 'yyyy-MM-dd'),
      format(subDays(now, 23), 'yyyy-MM-dd'),
      format(subDays(now, 22), 'yyyy-MM-dd'),
      format(subDays(now, 21), 'yyyy-MM-dd'),
      format(subDays(now, 20), 'yyyy-MM-dd'),
      format(subDays(now, 19), 'yyyy-MM-dd'),
      format(subDays(now, 18), 'yyyy-MM-dd'),
      format(subDays(now, 17), 'yyyy-MM-dd'),
      format(subDays(now, 16), 'yyyy-MM-dd'),
      format(subDays(now, 15), 'yyyy-MM-dd'),
      format(subDays(now, 14), 'yyyy-MM-dd'),
      format(subDays(now, 13), 'yyyy-MM-dd'),
      format(subDays(now, 12), 'yyyy-MM-dd'),
      format(subDays(now, 11), 'yyyy-MM-dd'),
      format(subDays(now, 10), 'yyyy-MM-dd'),
      format(subDays(now, 9), 'yyyy-MM-dd'),
      format(subDays(now, 8), 'yyyy-MM-dd'),
      format(subDays(now, 7), 'yyyy-MM-dd'),
      format(subDays(now, 6), 'yyyy-MM-dd'),
      format(subDays(now, 5), 'yyyy-MM-dd'),
      format(subDays(now, 4), 'yyyy-MM-dd'),
      format(subDays(now, 3), 'yyyy-MM-dd'),
      format(subDays(now, 2), 'yyyy-MM-dd'),
      format(subDays(now, 1), 'yyyy-MM-dd'),
      format(subDays(now, 0), 'yyyy-MM-dd')]
    ],
    type: "bar",
  };

  var CAHNNEL_DATA = {
    x: "x",
    xFormat: '%Y-%m-%d',  
    columns: [
      ["x", 
      format(subDays(now, 27), 'yyyy-MM-dd'),
      format(subDays(now, 26), 'yyyy-MM-dd'),
      format(subDays(now, 25), 'yyyy-MM-dd'),
      format(subDays(now, 24), 'yyyy-MM-dd'),
      format(subDays(now, 23), 'yyyy-MM-dd'),
      format(subDays(now, 22), 'yyyy-MM-dd'),
      format(subDays(now, 21), 'yyyy-MM-dd'),
      format(subDays(now, 20), 'yyyy-MM-dd'),
      format(subDays(now, 19), 'yyyy-MM-dd'),
      format(subDays(now, 18), 'yyyy-MM-dd'),
      format(subDays(now, 17), 'yyyy-MM-dd'),
      format(subDays(now, 16), 'yyyy-MM-dd'),
      format(subDays(now, 15), 'yyyy-MM-dd'),
      format(subDays(now, 14), 'yyyy-MM-dd'),
      format(subDays(now, 13), 'yyyy-MM-dd'),
      format(subDays(now, 12), 'yyyy-MM-dd'),
      format(subDays(now, 11), 'yyyy-MM-dd'),
      format(subDays(now, 10), 'yyyy-MM-dd'),
      format(subDays(now, 9), 'yyyy-MM-dd'),
      format(subDays(now, 8), 'yyyy-MM-dd'),
      format(subDays(now, 7), 'yyyy-MM-dd'),
      format(subDays(now, 6), 'yyyy-MM-dd'),
      format(subDays(now, 5), 'yyyy-MM-dd'),
      format(subDays(now, 4), 'yyyy-MM-dd'),
      format(subDays(now, 3), 'yyyy-MM-dd'),
      format(subDays(now, 2), 'yyyy-MM-dd'),
      format(subDays(now, 1), 'yyyy-MM-dd'),
      format(subDays(now, 0), 'yyyy-MM-dd')]
    ],
    type: "pie",
  };

  var MONTH_AXIS = {
    x: {
        type: "timeseries",
        tick: {
            count : 5,
            format: "%m.%d"
        }
      },
    y : {
        min : 0
    }
}

var CHANNEL_AXIS = {
    x: {
        type: "timeseries",
        tick: {
            count : 5,
            format: "%m.%d"
        }
      }
}
const MONTH_SIZE={
        height: 200
}

export default function Home() {
    const [chartData, setChartData] = useState(WEEK_CHART_DATA);
    const [monthData, setMonthData] = useState(MONTH_CHART_DATA);
    const [channelData, setChannelData] = useState(CAHNNEL_DATA);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const [lastday, setLastday ] = useState(format(subDays(tempDate, sub), 'yyyy-MM-dd'));
    const sdate1 = format(now, 'yyyy-MM-dd 00:00:00');
    const sdate2 = format(subDays(now, 6), 'yyyy-MM-dd 00:00:00');
    const sdate3 = format(now, 'yyyy-MM-01 00:00:00');
    const sdate4 = format(now, 'yyyy-01-01 00:00:00');
    const card = [
        { title : "오늘 방문자", num : countData.today, color : "#ef5f87", colorLight : "#f17e9e", icon: "faEye", sdate: sdate1 },
        { title : "최근 7일 누적 방문", num : countData.week, color : "#7c78b5", colorLight : "#9592c3", icon : "faPaperPlane", sdate: sdate2 },
        { title : "이번달 누적 방문", num : countData.month, color : "#45b5df", colorLight : "#69c3e4", icon : "faTag", sdate: sdate3 },
        { title : "올해 누적 방문", num : countData.year, color : "#57c285", colorLight : "#78cd9c", icon : "faUserFriends", sdate: sdate4 }
    ]
    let weekAxis = WEEK_AXIS;
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    function getTodayCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/today_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            countData.today = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getWeekCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/week_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            countData.week = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getMonthCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/month_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            countData.month = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getYearCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/year_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            countData.year = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleSelectedCenter(centerId){
        setSelectedCenter(centerId);
    }

    function getCenterWeekInfo(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/center_week",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                lastday : lastday
            }
        }).then(function (res){
            dateArray = ["방문자"];
            res.data.map((count) => dateArray.push(count.count))
            setChartData({
                columns: [
                    dateArray
                ],
                type: "spline",
                colors: {
                    이번주 : "#3bc0c3"
                    },
                categories: [
                    format(subDays(tempDate, sub+6), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+6), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+5), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+5), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+4), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+4), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+3), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+3), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+2), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+2), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+1), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub+1), 'iiii', {locale: ko}),
                    format(subDays(tempDate, sub+0), 'MM.dd ', {locale: ko}) + format(subDays(tempDate, sub), 'iiii', {locale: ko})
                ]
            });
        }).catch(error => console.log(error))
    }

    function getCenterMonthInfo(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/center_month",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                lastday : lastday
            }
        }).then(function (res){
            monthArray = ["방문자"];
            monthLineArray = ["line"];
            res.data.map((count) => monthArray.push(count.count))
            res.data.map((count) => monthLineArray.push(count.count))
            setMonthData({
                x: "x",
                xFormat: '%Y-%m-%d', 
                columns: [
                    ["x", 
                    format(subDays(now, 27), 'yyyy-MM-dd'),
                    format(subDays(now, 26), 'yyyy-MM-dd'),
                    format(subDays(now, 25), 'yyyy-MM-dd'),
                    format(subDays(now, 24), 'yyyy-MM-dd'),
                    format(subDays(now, 23), 'yyyy-MM-dd'),
                    format(subDays(now, 22), 'yyyy-MM-dd'),
                    format(subDays(now, 21), 'yyyy-MM-dd'),
                    format(subDays(now, 20), 'yyyy-MM-dd'),
                    format(subDays(now, 19), 'yyyy-MM-dd'),
                    format(subDays(now, 18), 'yyyy-MM-dd'),
                    format(subDays(now, 17), 'yyyy-MM-dd'),
                    format(subDays(now, 16), 'yyyy-MM-dd'),
                    format(subDays(now, 15), 'yyyy-MM-dd'),
                    format(subDays(now, 14), 'yyyy-MM-dd'),
                    format(subDays(now, 13), 'yyyy-MM-dd'),
                    format(subDays(now, 12), 'yyyy-MM-dd'),
                    format(subDays(now, 11), 'yyyy-MM-dd'),
                    format(subDays(now, 10), 'yyyy-MM-dd'),
                    format(subDays(now, 9), 'yyyy-MM-dd'),
                    format(subDays(now, 8), 'yyyy-MM-dd'),
                    format(subDays(now, 7), 'yyyy-MM-dd'),
                    format(subDays(now, 6), 'yyyy-MM-dd'),
                    format(subDays(now, 5), 'yyyy-MM-dd'),
                    format(subDays(now, 4), 'yyyy-MM-dd'),
                    format(subDays(now, 3), 'yyyy-MM-dd'),
                    format(subDays(now, 2), 'yyyy-MM-dd'),
                    format(subDays(now, 1), 'yyyy-MM-dd'),
                    format(subDays(now, 0), 'yyyy-MM-dd')
                    ],
                    monthArray,
                    monthLineArray
                ],
                types: {
                    방문자 : "bar",
                    line : "spline"
                },
                colors: {
                    line : "#3bc0c3"
                  }
            });
        }).catch(error => console.log(error))
    }

    function getCenterChannelInfo(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/center_channel",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid
            }
        }).then(function (res){
            ch01Array = ["인터넷"];
            ch02Array = ["전단지"];
            ch03Array = ["지인소개"];
            ch04Array = ["기타"];
            ch05Array = ["현수막"];
            ch06Array = ["간판"];
            ch07Array = ["블로그"];
            ch08Array = ["카페"];
            ch09Array = ["페이스북"];
            ch10Array = ["인스타"];

            res.data[0].map((item)=>ch01Array.push(item));
            res.data[1].map((item)=>ch02Array.push(item));
            res.data[2].map((item)=>ch03Array.push(item));
            res.data[3].map((item)=>ch04Array.push(item));
            res.data[4].map((item)=>ch05Array.push(item));
            res.data[5].map((item)=>ch06Array.push(item));
            res.data[6].map((item)=>ch07Array.push(item));
            res.data[7].map((item)=>ch08Array.push(item));
            res.data[8].map((item)=>ch09Array.push(item));
            res.data[9].map((item)=>ch10Array.push(item));

            setChannelData({
                x: "x",
                xFormat: '%Y-%m-%d', 
                columns: [
                    ["x", 
                    format(subDays(now, 27), 'yyyy-MM-dd'),
                    format(subDays(now, 26), 'yyyy-MM-dd'),
                    format(subDays(now, 25), 'yyyy-MM-dd'),
                    format(subDays(now, 24), 'yyyy-MM-dd'),
                    format(subDays(now, 23), 'yyyy-MM-dd'),
                    format(subDays(now, 22), 'yyyy-MM-dd'),
                    format(subDays(now, 21), 'yyyy-MM-dd'),
                    format(subDays(now, 20), 'yyyy-MM-dd'),
                    format(subDays(now, 19), 'yyyy-MM-dd'),
                    format(subDays(now, 18), 'yyyy-MM-dd'),
                    format(subDays(now, 17), 'yyyy-MM-dd'),
                    format(subDays(now, 16), 'yyyy-MM-dd'),
                    format(subDays(now, 15), 'yyyy-MM-dd'),
                    format(subDays(now, 14), 'yyyy-MM-dd'),
                    format(subDays(now, 13), 'yyyy-MM-dd'),
                    format(subDays(now, 12), 'yyyy-MM-dd'),
                    format(subDays(now, 11), 'yyyy-MM-dd'),
                    format(subDays(now, 10), 'yyyy-MM-dd'),
                    format(subDays(now, 9), 'yyyy-MM-dd'),
                    format(subDays(now, 8), 'yyyy-MM-dd'),
                    format(subDays(now, 7), 'yyyy-MM-dd'),
                    format(subDays(now, 6), 'yyyy-MM-dd'),
                    format(subDays(now, 5), 'yyyy-MM-dd'),
                    format(subDays(now, 4), 'yyyy-MM-dd'),
                    format(subDays(now, 3), 'yyyy-MM-dd'),
                    format(subDays(now, 2), 'yyyy-MM-dd'),
                    format(subDays(now, 1), 'yyyy-MM-dd'),
                    format(subDays(now, 0), 'yyyy-MM-dd')
                    ],
                    ch01Array,
                    ch02Array,
                    ch03Array,
                    ch04Array,
                    ch05Array,
                    ch06Array,
                    ch07Array,
                    ch08Array,
                    ch09Array,
                    ch10Array
                ],
                type: "area-step"
            });
        }).catch(error => console.log(error))
    }

    function subPre(){
        sub += 7
        setLastday(format(subDays(tempDate, sub), 'yyyy-MM-dd'));
    }

    function subNext(){
        if(sub > 0){
            sub -= 7;
            setLastday(format(subDays(tempDate, sub), 'yyyy-MM-dd'));
        }
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getTodayCount();
            getWeekCount();
            getMonthCount();
            getYearCount();
            getCenterWeekInfo();
            getCenterMonthInfo();
            getCenterChannelInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getTodayCount();
            getWeekCount();
            getMonthCount();
            getYearCount();
            getCenterWeekInfo();
            getCenterMonthInfo();
            getCenterChannelInfo();
            sub = 0;
        }
    },[selectedCenter]);

    useEffect(()=>{
        getCenterWeekInfo();
    }, [sub]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner home">
            <h3>대시보드</h3>
            <div className="content-component">
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
            <div className ="card-container">
                {card.map((card, index) => {
                    return(
                        <HomeCard key={index} cardList = {card} centerId = {selectedCenter} />
                    )
                })}
            </div>

            <div className="chart-container">
                <div className="week-chart-container">
                <h4>최근 7일 방문자 기록</h4>
                    <div className="week-range">
                    <a href="#"><Button className="mr-1 btn-secondary" size="sm" onClick={subPre}><FontAwesomeIcon icon={faAngleLeft} /></Button></a>
                    <Moment format="YYYY.MM.DD" subtract={{ days: sub+6 }}>{tempDate}</Moment>
                    ~
                    <Moment format="YYYY.MM.DD" subtract={{ days: sub}}>{tempDate}</Moment>
                    <a href="#"><Button className="ml-1 btn-secondary" size="sm" onClick={subNext}><FontAwesomeIcon icon={faAngleRight} /></Button></a>
                    <BillboardChart data={chartData} axis={weekAxis} />
                    </div>
                </div>
                <div className="month-chart-container">
                <h4>최근 4주간 방문자 기록</h4>
                <BillboardChart data={monthData} axis={MONTH_AXIS} size={MONTH_SIZE} />
                <h4>최근 4주간 유입경로</h4>
                <BillboardChart data={channelData} axis={CHANNEL_AXIS} size={MONTH_SIZE} />
                </div>
            </div>
            </div>
            </div>
            </div>
        </main>
    )
}