import React,{useEffect,useState} from "react";
import {Nav} from 'react-bootstrap';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import DecodeData from '../util/DecodeData';

const NavContainer = styled.div`
    padding:50px;
    background-color:#e6e7e8;
    word-break:keep-all;
    text-align:center;
` 

function LeftNav () {

    const [GateMenu, setGateMenu] = useState(false);
    const data = useSelector(state => state.data);   

    // 최고 관리자는 문열기 버튼 안보이도록
    // (비상 개방은 지점 관리자가 관리)
    useEffect(()=>{
        DecodeData(data.url)
        .then( result => {
            const level = result.level;
            if(typeof(level) === 'number' && level === 100) {
              setGateMenu(true);
            }
        })
        .catch( error => alert(error) )
    },[]);   

    return (
        <Nav className="flex-column">
        <Nav.Link href="/home">대시보드</Nav.Link>
        <Nav.Link href="/smartkey">스마트키</Nav.Link>
        <Nav.Link href="/visit/center/month">방문 관리</Nav.Link>
        <Nav.Link href="/client">고객 관리</Nav.Link>
        <Nav.Link href="/purchase">구매 관리</Nav.Link>
        <Nav.Link href="/teacher">강사 관리</Nav.Link>
        <Nav.Link href="/item">상품 관리</Nav.Link>
        <Nav.Link href="/center">가맹점 관리</Nav.Link>
        {
            GateMenu ? <Nav.Link href="/gate">출입문 관리</Nav.Link> : null
        }
        </Nav>
    )
} 

function MainNav () {
    return (
        <NavContainer>
            <LeftNav />
        </NavContainer>
    );
}

export default MainNav;