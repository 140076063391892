import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table, Button } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import format from 'date-fns/format';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import Filter from '../util/Filter';
import { subMonths } from 'date-fns';
import Payment from '@material-ui/icons/Payment';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import NumberFormat from 'react-number-format';
import paymentData from '../util/paymentData.json';
import ReactTooltip from "react-tooltip";
import SelectPriod from "../util/SelectPriod";
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var purchaseData = '';
var clear = false;
var name = '';
var priodClear = false;

const now = new Date();
const month = subMonths(now, 1);
const monthdate = new Date(month.getFullYear(), month.getMonth(), month.getDate(), 0, 0, 0);

var filterMode = {
    name : true,
    phone : false
}

let storage = {
    nameKeyword : '',
    purchaseCount : 0,
    purchaseSum : 0
}

let loaded = false;
let phoneIcon = false;

export default function Purchase() {
    let sdate = monthdate;
    const [selectedCenter, setSelectedCenter ] = useState('');
    const [startDate, setStartDate] = useState(sdate);
    const [endDate, setEndDate] = useState(new Date());
    const [purchase, setPurchase] = useState([]);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(purchaseData,page,pageSize);
        
        totalCount = purchaseData.length;

        setPurchase(pageCurrent.data);
    }
    
    function handleStartChange(date) {
        setStartDate(date);
        priodClear = true;
    }

    function handleEndChange(date) {
        setEndDate(date);
        priodClear = true;
    }
    
    function getPurchase(){
        const formatStartDate = format(startDate, 'yyyyMMdd');
        const formatEndDate = format(endDate, 'yyyyMMdd')
        const sdate = formatStartDate + "000000";
        const edate = formatEndDate + "235959";

        if(clear === true){
            name = ''
        }else{
            name = storage.nameKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/purchase_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                startDate : sdate,
                endDate : edate
            }
        }).then(function (res){
            storage.purchaseCount = res.data[0].count;
            storage.purchaseSum = res.data[0].sum;

            axios({
                url : data.url + "/slim/purchase",
                method : "post",
                data : {
                    sid : 'smartgym',
                    cid : temp_cid,
                    nameKeyword : name,
                    startDate : sdate,
                    endDate : edate
                }
            }).then(function (res){
                purchaseData = res.data;
                const page = activePage;
                const pageCurrent = pagiarray(purchaseData,page,pageSize);
                
                totalCount = purchaseData.length;
    
                setPurchase(pageCurrent.data);
            }).catch(function(error){
                console.log(error);
            })

        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClear(){
        storage.nameKeyword = '';
        setStartDate(monthdate);
        setEndDate(new Date());
        clear = true;
        activePage = 1;
        getPurchase();
    }

    function handleSelectedPriod(sdate, edate){
        setStartDate(new Date(sdate));
        setEndDate(edate);
        getPurchase();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        activePage = 1;
        clear = false;
        getPurchase();
    }

    function handleAdd(){
        window.location.href = '/purchaseInfoAdd';
    }

    function handleClick(pcd){
        window.location.href = '/purchaseInfo/'+pcd;
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getPurchase();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getPurchase();
            activePage = 1;
        }
    },[selectedCenter]);

    useEffect(()=>{
        if(loaded){
            getPurchase();
            activePage = 1;
        }
    },[startDate, endDate]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner purchase">
            <h3 style={{display : "inline-block"}}>구매 관리</h3>
            <span style={{float : "right", paddingTop:"10px"}}><NumberFormat value={storage.purchaseSum} displayType={'text'} thousandSeparator={true} suffix={'원'} /> {"/" + storage.purchaseCount + "건"}</span>
            <div className="content-component">
                <div className="content-header">
                    <Button className="add-button" onClick={() => handleAdd()}><Payment /> &nbsp; 구매 추가 </Button>
                    <SelectCenter 
                        onSelectedCenter={handleSelectedCenter}
                    />
                </div>
                <div className="content-body">
                    <div className="table-header">
                        <div className="search-left-container">
                        <span className="mr-1 white-title">날짜&nbsp;</span>
                        <DatePicker
                            selected = {startDate}
                            onChange={handleStartChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            locale="ko"
                        />
                        <span>&nbsp;~&nbsp;</span>
                        <DatePicker
                            selected = {endDate}
                            onChange={handleEndChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            style={{width:'100px'}}
                            locale="ko"
                        />
                        <SelectPriod onSelectedPriod={handleSelectedPriod} onClear = {priodClear}/>
                        </div>
                        <Filter 
                            handleFilter = {handleFilter}
                            handleClear = {handleClear}
                            mode = {filterMode}
                        />
                    </div>
                    <div className="table-body">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'200px'}}>구매일자</th>
                                <th style={{width:'100px'}}>고객명</th>
                                <th style={{width:'310px'}}>종목</th>
                                <th style={{width:'220px'}}>유효기간</th>
                                <th style={{width:'120px'}}>구매금액</th>
                                <th style={{width:'100px'}}>결제구분</th>
                                <th style={{width:'150px'}}>가맹점</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {purchase.map(( purchase, index ) => {
                            const { pcd, purchaseDate, name, phone, itemName1, itemName2, sdate, edate, price, method, center, location } = purchase;
                            var year1 = purchaseDate.substring(0, 4);
                            var month1 = purchaseDate.substring(4, 6);
                            var day1 = purchaseDate.substring(6, 8);
                            var hour1 = purchaseDate.substring(8, 10);
                            var min1 = purchaseDate.substring(10, 12);
                            var sec1 = purchaseDate.substring(12, 14);
                            var formatPurchaseDate = year1 + '-' + month1 + "-" + day1 +" " + hour1 + ":"+ min1 + ":"+ sec1;

                            var year2 = sdate.substring(0, 4);
                            var month2 = sdate.substring(4, 6);
                            var day2 = sdate.substring(6, 8);
                            var formatSdate = year2 + '-' + month2 + "-" + day2;

                            var year3 = edate.substring(0, 4);
                            var month3 = edate.substring(4, 6);
                            var day3 = edate.substring(6, 8);
                            var formatEdate = year3 + '-' + month3 + "-" + day3;

                            var paymentValue = '';
                            if(method){
                                if(paymentData.find(element => element.value === method)){
                                    paymentValue = paymentData.find(element => element.value === method).name
                                }
                            }
                            if(method === "1"){
                                phoneIcon = true;
                            }else{
                                phoneIcon = false;
                            }
                            return(
                                <tr key={index} onClick={() => handleClick(pcd)}>
                                    <td>{formatPurchaseDate}</td>
                                    <td className="td-icon"><span data-tip={phone}>
                                        {
                                            phoneIcon ?
                                            <PhoneIphoneIcon/>
                                            :
                                            null
                                        }
                                        {name}
                                        </span><ReactTooltip />
                                    </td>
                                    <td className="td-itemname">{itemName1 + " " + itemName2}</td>
                                    <td>{formatSdate + " ~ " +formatEdate}</td>
                                    <td><NumberFormat value={price} displayType={'text'} thousandSeparator={true} suffix={'원'} /></td>
                                    <td>{paymentValue}</td>
                                    <td>{center + " " +location}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}