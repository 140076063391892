import React, { useEffect, useState } from "react";
import Select from 'react-select';
import '../components/Main.css';
import { format, subDays, subMonths } from 'date-fns';
  
var now = new Date();
var priodOption = [
    {value : format(subDays(now, 0), 'yyyy-MM-dd 00:00:00'), label : "오늘"},
    {value : format(subDays(now, 1), 'yyyy-MM-dd 00:00:00'), label : "어제"},
    {value : format(subDays(now, 6), 'yyyy-MM-dd 00:00:00'), label : "최근 일주일"},
    {value : format(now, 'yyyy-MM-01 00:00:00'), label : "이번달"},
    {value : format(subMonths(now, 3), 'yyyy-MM-dd 00:00:00'), label : "3개월"},
    {value : format(subMonths(now, 6), 'yyyy-MM-dd 00:00:00'), label : "6개월"}
];

export default function SelectPriod({onSelectedPriod, onClear}) {
    const [selectPriod, setSelectPriod] = useState('');

    function handleSelectChange(selectedOption){
        setSelectPriod(selectedOption);
        var edate = new Date();
        if(selectedOption.label === "어제"){
            edate = new Date(subDays(edate, 1))
        }
        edate.setHours(23,59,59,59);
        onSelectedPriod(selectedOption.value, edate)
    }

    useEffect(()=>{
        if(onClear){
            setSelectPriod(null);
            onClear = false;
        }
    },[onClear]);

    return (
        <div className="select-priod-container">
            <div className="select-priod">
                <Select 
                    placeholder="기간 선택"
                    value={selectPriod}
                    onChange={handleSelectChange}
                    options = {priodOption}
                />
            </div>
        </div>
    )
}