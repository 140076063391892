import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table} from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenterOption from "../util/SelectCenterOption";
import FilterCenter from '../util/FilterCenter';
import BusinessCenter from '@material-ui/icons/BusinessCenter';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var clear = false;
var centerData = '';
var centerValue = '';
var centerOptionValue = '';

let storage = {
    centerKeyword : ''
}

export default function Center() {
    const [centerInfo, setCenterInfo] = useState([]);
    const [selectedCenterOption, setSelectedCenterOption ] = useState('');
    const [isRoot, setIsRoot] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    function handleSelectedCenterOption(centerOption){
        setSelectedCenterOption(centerOption);
    }

    function handleClear(){
        storage.centerKeyword = '';
        setSelectedCenterOption('');
        clear = true;
        getCenterInfo();
    }

    function handleFilter(filterData){
        storage.centerKeyword = filterData.centerKeyword;
        activePage = 1;
        getCenterInfo();
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(centerData,page,pageSize);
        
        totalCount = centerData.length;

        setCenterInfo(pageCurrent.data);
    }

    function getCenterInfo(){
        if(data.cid === 'root'){
            if(clear === true){
                centerValue = ''
                centerOptionValue = ''
            }else{
                centerValue = storage.centerKeyword;
                centerOptionValue = selectedCenterOption;
            }
            axios({
                url : data.url + "/slim/center",
                method : "post",
                data : {
                    sid : 'smartgym',
                    centerOption : centerOptionValue,
                    centerKeyword : centerValue
                }
            }).then(function (res){
                centerData = res.data;
                const page = activePage;
                const pageCurrent = pagiarray(centerData,page,pageSize);
                
                totalCount = centerData.length;
    
                setCenterInfo(pageCurrent.data);
    
                clear = false;
            }).catch(function(error){
                console.log(error);
            })
        }else{
            axios({
                url : data.url + "/slim/mycenter",
                method : "post",
                data : {
                    sid : 'smartgym',
                    cid : data.cid
                }
            }).then(function (res){
                centerData = res.data;
                const page = activePage;
                const pageCurrent = pagiarray(centerData,page,pageSize);
                
                totalCount = centerData.length;
    
                setCenterInfo(pageCurrent.data);
    
                clear = false;
            }).catch(function(error){
                console.log(error);
            })
        }
        
    }

    function handleClick(cid){
        window.location.href = '/centerInfo/'+cid;
    }

    function handleAdd(){
        window.location.href = '/centerInfoAdd'
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            console.log(decodeResult);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            if(data.cid === "root"){
                setIsRoot(true);
            }
            getCenterInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3 style={{display : "inline-block"}}>가맹점 관리</h3>
            <div className="content-component">
                <div className="content-header">
                    {
                        isRoot ?
                        <Button className="add-button" onClick={() => handleAdd()}><BusinessCenter /> &nbsp; 가맹점 추가 </Button>
                        : null
                    }
                </div>
                <div className="content-body">
                    {
                        isRoot ?
                        <div className="table-header">
                            <div></div>
                            <div style={{display : "inline-flex"}}>
                            <SelectCenterOption 
                                onSelectedCenterOption={handleSelectedCenterOption}
                            />
                            &nbsp;&nbsp;
                            <FilterCenter 
                                handleFilter = {handleFilter}
                                handleClear = {handleClear}
                            />
                            </div>
                        </div>
                        :
                        <div className="table-header">
                        </div>
                    }
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'200px'}}>가맹점</th>
                                <th style={{width:'190px'}}>연락처</th>
                                <th style={{width:'550px'}}>주소</th>
                                <th style={{width:'260px'}}>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {centerInfo.map(( centerInfo, index ) => {
                            const { cid, phone, center, location, address, regdate } = centerInfo;
                            // var formatRegdate = format(new Date(regdate), 'yyyy-MM-dd hh:mm');
                            var formatRegdate = regdate.substring(0,16);
                            var num1 = phone.substring(0,3);
                            var num2 = phone.substring(3,6);
                            var num3 = phone.substring(6,10);
                            var formatPhone = (num1 + '-' + num2 + "-" + num3);

                            return(
                                <tr key={index} onClick={() => handleClick(cid)} >
                                    <td>{center + " " +location}</td>
                                    <td>{formatPhone}</td>
                                    <td className="td-address">{address}</td>
                                    <td className="td-regdate">{formatRegdate}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}