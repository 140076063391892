import React, { useState, useEffect, useReducer } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import SelectCenter from "../util/SelectCenter";
import ButtonStyle from '../util/ButtonStyle';
import DaumPostcodePopup from '../util/DaumPostCodePopup';
import format from 'date-fns/format';
import { useSelector, useDispatch } from 'react-redux';

var pathOption = [];
var aimOption = [];

const initialState = {
    name : '',
    phone1 : '',
    phone2 : '',
    phone3 : '',
    gender : '',
    address : '',
    birthday : null,
    path : '',
    aim : '',
    sms : '1',
    push : '1',
    memo : '',
}

function reducer(state, {field, value}){
    return {
        ...state,
        [field] : value
    }
}

export default function ClientInfoAdd() {
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [loaded, setLoaded] = useState(0);
    const [showPostCode, setShowPostCode] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [duplicateChecked, setDuplicatedChecked] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    const onChange = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.name == "phone1" || e.target.name == "phone2" || e.target.name == "phone3"){
            if (e.target.value == '' || re.test(e.target.value)) {
                dispatchState({field : e.target.name , value : e.target.value})
            }
        }else{
            dispatchState({field : e.target.name , value : e.target.value})
        }
    }
    const { name, phone1, phone2, phone3, gender, address, birthday, path, aim, sms, push, memo } = state;

    function getPathOption(){
        axios({
            url : data.url + "/slim/path_option",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(function (res){
            res.data.map((options) => {
                pathOption.push({ value : options.value , label : options.label})
            });
        }).catch(function(error){
            console.log(error);
        })
    }

    function getAimOption(){
        axios({
            url : data.url + "/slim/aim_option",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(function (res){
            res.data.map((options) => {
                aimOption.push({value : options.value , label : options.label})
            });
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleAdd() {
        if(!selectedCenter){
            alert("센터를 선택해 주세요.")
            return false;
        }
        if(!name){
            alert("이름을 입력해 주세요.")
            return false;
        }
        if(!birthday){
            alert("생년월일을 입력해 주세요.")
            return false;
        }
        if(!gender){
            alert("성별을 입력해 주세요.")
            return false;
        }
        // 휴대전화 중복체크
        if(!duplicateChecked){
            alert("휴대폰 중복체크를 해주세요.")
            return false;
        }
        if(!address){
            alert("주소를 입력해 주세요.")
            return false;
        }
        var formatBirthday = format(birthday, 'yyyyMMdd');
        var pathValue = '';
        var aimValue = '';
        var formatPhone = phone1+""+phone2+""+phone3;
        if(path){
            pathValue = path.value;
        }
        if(aim){
            aimValue = aim.value;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/add_client_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                name : name,
                birthday : formatBirthday,
                phone : formatPhone,
                gender : gender,
                address : address,
                path : pathValue,
                aim : aimValue,
                sms : sms,
                push : push,
                memo : memo,
                pw : phone3
            }
        }).then(function (response){
            alert("등록 되었습니다.");
            window.location.href = '/clientInfo/'+response.data;
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleBirthdayChange(date){
        dispatchState({field : "birthday" , value : date})
    }

    function openPostCode(){
        setShowPostCode(true);
    }

    function closePopup() {
        setShowPostCode(false);
    }

    function selectAddress(selectedAddress){
        setShowPostCode(false);
        dispatchState({ field : "address" , value : selectedAddress})
    }

    function handlePathChange(value){
        dispatchState({ field : "path" , value : value})
    }

    function handleAimChange(value){
        dispatchState({ field : "aim" , value : value})
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function duplicateCheck(){
        var formatPhone = phone1+""+phone2+""+phone3;
        axios({
            url : data.url + "/slim/phone_dupe",
            method : "post",
            data : {
                sid : 'smartgym',
                phone : formatPhone
            }
        }).then(function (response){
            if(response.data.ret === "Y"){
                alert("사용가능한 번호입니다.")
                setDuplicatedChecked(true);
            }else{
                alert(response.data.msg)
            }
        }).catch(function (error){
            console.log(error);
        })
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            console.log(data);
            getPathOption();
            getAimOption();
            setLoaded(1);
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>고객 추가</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body client-info-add">
                <div className="info-table-left text-left">
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>가맹점</td>
                                <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} width="w-100"/>
                                </td>
                            </tr>
                            <tr>
                                <td>고객명</td>
                                <td>
                                    <input type="text" name="name" value={name} onChange={onChange} className="form-control w-25" />
                                </td>
                            </tr>
                            <tr>
                                <td>생년월일</td>
                                <td>
                                    <DatePicker
                                        selected = {birthday}
                                        onChange={handleBirthdayChange}
                                        dateFormat="yyyy-MM-dd"
                                        className="form-control"
                                        locale="ko"
                                        showYearDropdown
                                    />
                                    &nbsp;&nbsp;
                                    <div className="d-inline-block">
                                    <input type="radio" name="gender" value="1" onChange={onChange}/> 남자&nbsp;&nbsp;
                                    <input type="radio" name="gender" value="2" onChange={onChange}/> 여자
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>휴대전화</td>
                                <td>
                                    {duplicateChecked ?
                                        <div>
                                            <input type="text" name="phone1" maxLength="3" className="form-control d-inline-block w-25" value={phone1} onChange={onChange} readOnly/> -&nbsp;
                                            <input type="text" name="phone2" maxLength="4" className="form-control d-inline-block w-25" value={phone2} onChange={onChange} readOnly/> -&nbsp;
                                            <input type="text" name="phone3" maxLength="4" className="form-control d-inline-block w-25" value={phone3} onChange={onChange} readOnly/>
                                        </div>
                                        :
                                        <div>
                                            <input type="text" name="phone1" maxLength="3" className="form-control d-inline-block w-25" value={phone1} onChange={onChange}/> -&nbsp;
                                            <input type="text" name="phone2" maxLength="4" className="form-control d-inline-block w-25" value={phone2} onChange={onChange}/> -&nbsp;
                                            <input type="text" name="phone3" maxLength="4" className="form-control d-inline-block w-25" value={phone3} onChange={onChange}/>
                                            <ButtonStyle mode="check" title="중복체크" buttonClick={() => duplicateCheck()}/>
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>주소</td>
                                <td>
                                <textarea className="form-control mb-1" value={address} readOnly/>
                                <input type="button" onClick={()=> openPostCode()} value="주소 검색"></input>
                                </td>
                            </tr>	
                            <tr>
                                <td>고객 방문경로</td>
                                <td style={{overflow : "initial"}}>
                                <Select 
                                    value ={path}
                                    placeholder="방문경로"
                                    onChange={handlePathChange}
                                    options = {pathOption}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>운동목적</td>
                                <td style={{overflow : "initial"}}>
                                <Select 
                                    value ={aim}
                                    placeholder="운동목적"
                                    onChange={handleAimChange}
                                    options = {aimOption}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>마케팅 수신 동의</td>
                                <td>
                                    SMS&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sms" value="1" defaultChecked onChange={onChange}/> 동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sms" value="2" onChange={onChange}/> 비동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <br />
                                    PUSH&nbsp;&nbsp;
                                    <input type="radio" name="push" value="1" defaultChecked onChange={onChange}/> 동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="push" value="2" onChange={onChange}/> 비동의&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>회원메모</td>
                                <td>
                                    <div className="memo">
                                    <textarea name="memo" className="form-control" value={memo} onChange={onChange}>
                                    </textarea>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="button-container">
                        <ButtonStyle mode="add" title="등록" buttonClick={() => handleAdd()}/>
                    </div>
                </div>
                </div>
            </div>
            {showPostCode ? 
                <DaumPostcodePopup
                    selectAddress = {selectAddress}
                    closePopup={closePopup}
                />
            : null
            }
            </div>
            </div>
        </main>
    )
}