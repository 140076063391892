import React, { useEffect, useState } from "react";
import axios from 'axios';
import Select from 'react-select';
import '../components/Main.css';
import { useSelector } from 'react-redux';
  
var centerOption = [{value : '', label : "센터를 선택해 주세요", disabled : true}];
let defaultCenter = '';

export default function SelectCenter({onSelectedCenter, width, defaultValue }) {
    const [selectCenter, setSelectCenter ] = useState({value : ' ', label : "센터를 선택해 주세요", disabled : true});
    const [centerName, setCenterName] = useState('');
    const [centerMode, setCenterMode] = useState(false);
    const data = useSelector(state => state.data);
    defaultCenter = defaultValue;

    function getCenterList(){
        axios({
            url : data.url + "/slim/center_list",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(res => {
            res.data.map((list)=> {
                const { cid, center, location } = list;
                centerOption.push({ value : cid, label : center +" "+ location})
            });
            if(defaultCenter){
                setSelectCenter(centerOption.find(element => element.value === defaultCenter))
                onSelectedCenter(defaultCenter);
            }
        })
    }

    function getCenterName(){
        axios({
            url : data.url + "/slim/center_name",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : data.cid,
            }
        }).then(function (res){
            if(res.data.length !== 0){
                setCenterName(res.data[0].center + " " +res.data[0].location)
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleSelectChange(selectedOption){
        setSelectCenter(selectedOption);
        onSelectedCenter(selectedOption.value)
    }
    useEffect(()=>{
        console.log(data.cid);
        if(data.cid !== 'root'){
            getCenterName();
            setCenterMode(true);
            onSelectedCenter(data.cid);
        }else{
            getCenterList();
            setCenterMode(false);
        }
    },[data.cid]);

    return (
        <div className="select-container">
            {
                centerMode ?
                <span>{centerName}</span>
                :
                <div className={"select-center "+width}>        
                    <Select 
                    placeholder="가맹점 선택"
                    value={selectCenter}
                    isOptionDisabled = {(option) => option.disabled === true}
                    onChange={handleSelectChange}
                    options = {centerOption}
                    />
                </div>
            }
        </div>
    )
}