import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import ButtonStyle from '../util/ButtonStyle';
import SelectCenter from "../util/SelectCenter";
import DaumPostcodePopup from '../util/DaumPostCodePopup';
import format from 'date-fns/format';
import { useSelector, useDispatch } from 'react-redux';

export default function TeacherInfoEdit({match}) {
    const tcd = match.params.tcd;
    const [personInfo, setPersonInfo] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [cid, setCid] = useState('');
    const [formatPhone, setFormatPhone] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function getTeacherPersonInfo(){
        axios({
            url : data.url + "/slim/teacher_person_info",
            method : "post",
            data : {
                sid : 'smartgym',
                tcd : tcd
            }
        }).then(function (res){
            console.log(res.data[0]);
            setPersonInfo(res.data[0]);
            setCid(res.data[0].cid);
            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,7);
            var num3 = res.data[0].phone.substring(7,11);
            setFormatPhone(num1 + '-' + num2 + "-" + num3);
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleEdit() {
        axios({
            url : data.url + "/slim/edit_teacher_info",
            method : "post",
            data : {
                sid : 'smartgym',
                tcd : tcd,
                cid : selectedCenter
            }
        }).then(function (response){
            alert("수정되었습니다.");
            // window.location.reload();
            window.location.href = '/teacherInfo/'+tcd;
            
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function moveBack() {
        window.history.back();
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getTeacherPersonInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>강사 정보 수정</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body teacher-info-edit">
                <div className="info-table-left text-left">
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>가맹점</td>
                                <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} defaultValue={cid} width="w-100"/></td>
                            </tr>
                            <tr>
                                <td>강사명</td>
                                <td>{personInfo.name}</td>
                            </tr>
                            <tr>
                                <td>휴대전화</td>
                                <td>
                                    <input type="text" className="input-readonly form-control w-50 mb-1" value={formatPhone} readOnly/>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="button-container">
                        <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
                        <ButtonStyle mode="list" title="뒤로" buttonClick={() => moveBack()}/>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}