import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import ButtonStyle from '../util/ButtonStyle';
import SearchTeacherPopup from '../util/SearchTeacherPopup';
import DaumPostcodePopup from '../util/DaumPostCodePopup';
import { useSelector, useDispatch } from 'react-redux';

export default function CenterInfoEdit({match}) {
    const cid = match.params.cid;
    const [center1, setCenter1] = useState('');
    const [center2, setCenter2] = useState('');
    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [phone3, setPhone3] = useState('');
    const [address, setAddress] = useState('');
    const [manager1, setManager1] = useState({name : '', tcd: ''});
    const [manager2, setManager2] = useState({name : '', tcd: ''});
    const [showPostCode, setShowPostCode] = useState(false);
    const [showSearch1, setShowSearch1] = useState(false);
    const [showSearch2, setShowSearch2] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function getCenterDetailInfo(){
        axios({
            url : data.url + "/slim/center_detail_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : cid
            }
        }).then(function (res){
            setAddress(res.data[0].address);

            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,6);
            var num3 = res.data[0].phone.substring(6,10);
            setPhone1(num1);
            setPhone2(num2);
            setPhone3(num3);

            setCenter1(res.data[0].center);
            setCenter2(res.data[0].location);

            var managerCode = res.data[0].manager;
            getManager(managerCode);

        }).catch(function(error){
            console.log(error);
        })
    }

    function getManager(managerCode){
        axios({
            url : data.url + "/slim/manager_name",
            method : "post",
            data : {
                sid : 'smartgym',
                code : managerCode
            }
        }).then(function (res){
            var managerData = res.data;
            if(managerData[0]){
                setManager1(managerData[0]);
            }
            if(managerData[1]){
                setManager2(managerData[1]);
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleEdit(){
        var formatPhone = phone1+""+phone2+""+phone3;
        var formatManager = '';
        formatManager += "A#"+manager1.tcd;
        formatManager += "|Z#"+manager2.tcd;

        axios({
            url : data.url + "/slim/edit_center_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : cid,
                center1 : center1,
                center2 : center2,
                phone : formatPhone,
                address : address,
                manager : formatManager
            }
        }).then(function (response){
            alert("수정되었습니다.");
            window.location.href = '/centerInfo/'+cid;
        }).catch(function (error){
            console.log(error);
        })
    }

    function moveBack() {
        window.history.back();
    }

    function handleCenter1Change(event){
        setCenter1(event.target.value);
    }

    function handleCenter2Change(event){
        setCenter2(event.target.value);
    }

    function handlePhone1Change(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPhone1(event.target.value);
        }
    }

    function handlePhone2Change(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPhone2(event.target.value);
        }
    }

    function handlePhone3Change(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPhone3(event.target.value);
        }
    }

    function openPostCode(){
        setShowPostCode(true);
    }

    function closePopup() {
        setShowPostCode(false);
    }

    function selectAddress(selectedAddress){
        setShowPostCode(false);
        setAddress(selectedAddress);
    }

    function toggleSearch1() {
        setShowSearch1(!showSearch1)
    }

    function toggleSearch2() {
        setShowSearch2(!showSearch2)
    }

    function onInfoSubmit1(name, tcd) {
        setManager1({name : name, tcd: tcd});
    }

    function onInfoSubmit2(name, tcd) {
        setManager2({name : name, tcd: tcd});
    }

    function deleteSearch1(){
        setManager1({name : '', tcd: ''})
    }

    function deleteSearch2(){
        setManager2({name : '', tcd: ''})
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getCenterDetailInfo(); 
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>가맹점 정보 수정</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body center-info">
                <div className="info-table-left text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>
                                <input type="text" placeholder="본사명" value={center1} onChange={handleCenter1Change}/>&nbsp;&nbsp;
                                <input type="text" placeholder="지점명" value={center2} onChange={handleCenter2Change}/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <input type="text" maxLength="3" className="form-control d-inline-block w-25" value={phone1} onChange={handlePhone1Change}/> -&nbsp;
                                <input type="text" maxLength="4" className="form-control d-inline-block w-25" value={phone2} onChange={handlePhone2Change}/> -&nbsp;
                                <input type="text" maxLength="4" className="form-control d-inline-block w-25" value={phone3} onChange={handlePhone3Change}/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <textarea className="form-control mb-1" value={address} readOnly/>
                                <ButtonStyle mode="list" title="주소 검색" buttonClick={()=> openPostCode()} />
                            </td>
                        </tr>
                        <tr>
                            <td>담당자</td>
                            <td>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>주 담당자</td>
                                            <td>
                                                <input type="text" className="input-readonly" value={manager1.name} readOnly/>
                                                <Button className="ml-1" onClick={toggleSearch1} size="sm">검색</Button>
                                                <Button className="ml-1 btn-danger" onClick={deleteSearch1} size="sm">삭제</Button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>부 담당자</td>
                                            <td>
                                                <input type="text" className="input-readonly" value={manager2.name} readOnly/>
                                                <Button className="ml-1" onClick={toggleSearch2} size="sm">검색</Button>
                                                <Button className="ml-1 btn-danger" onClick={deleteSearch2} size="sm">삭제</Button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
                    <ButtonStyle mode="list" title="뒤로" buttonClick={() => moveBack()}/>
                </div>
                </div>
                </div>
            </div>
            {showSearch1 ? 
            <SearchTeacherPopup
                closePopup={toggleSearch1}
                onSubmit = {onInfoSubmit1}
            />
            : null
            }
            {showSearch2 ? 
            <SearchTeacherPopup
                closePopup={toggleSearch2}
                onSubmit = {onInfoSubmit2}
            />
            : null
            }
            {showPostCode ? 
            <DaumPostcodePopup
            selectAddress = {selectAddress}
            closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}