import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Form } from 'react-bootstrap';
import ButtonStyle from '../util/ButtonStyle';
import Draggable from 'react-draggable';
import Select from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCenter from "../util/SelectCenter";
import SearchClientPopup from "./SearchClientPopup";
import { confirmAlert } from 'react-confirm-alert';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from 'react-redux';

function ItemPopup(props) {
    const [gubun, setGubun] = useState('');
    const [detail, setDetail] = useState('');
    const [price, setPrice] = useState('');
    const [priod, setPriod] = useState('');
    const [unit, setUnit] = useState('');
    const [priority, setPriority] = useState('');
    const [state, setState] = useState({ value : '', label : '노출' });
    const [showSearch, setShowSearch] = useState(false);
    const [icd, setIcd] = useState('');
    const [cid, setCid] = useState('');
    const [selectedCenter, setSelectedCenter] = useState('');
    const [title, setTitle] = useState('');
    const data = useSelector(state => state.data);

    const unitOptions = [
        { value : 1, label : '일' },
        { value : 2, label : '개월' }
    ]

    const stateOptions = [
        { value : '1', label : '노출안함' },
        { value : '', label : '노출' }
    ]

    function toggleSearch() {
        setShowSearch(!showSearch)
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleUnitChange(selectedOption){
        setUnit(selectedOption)
    }

    function handleStateChange(selectedOption){
        setState(selectedOption)
    }

    function onInfoSubmit(name, icd) {
        // setName(name);
        setIcd(icd);
    }

    function handleSubmit () {
        if(!selectedCenter){
            alert("센터를 선택해 주세요.")
            return false;
        }
        if(!gubun){
            alert("구분값을 입력해 주세요.")
            return false;
        }
        if(!detail){
            alert("상세정보를 입력해 주세요.")
            return false;
        }
        if(!priod){
            alert("이용기간을 입력해 주세요.")
            return false;
        }
        if(!unit){
            alert("이용기간 단위를 선택해 주세요.")
            return false;
        }
        if(!priority){
            alert("노출순위를 입력해 주세요.")
            return false;
        }


        axios({
            url : data.url + "/slim/add_item",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : selectedCenter,
                gubun : gubun,
                detail : detail,
                price : price,
                priod : priod,
                unit : unit.value,
                priority : priority,
                state : state.value
            }
        }).then(function (response){
            console.log(data);
            alert("등록되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleEdit() {
        console.log(state);
        axios({
            url : data.url + "/slim/edit_item",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : selectedCenter,
                icd : icd,
                gubun : gubun,
                detail : detail,
                price : price,
                priod : priod,
                unit : unit.value,
                priority : priority,
                state : state.value
            }
        }).then(function (response){
            alert("수정되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })

    }

    function deleteKey() {
        axios({
            url : data.url + "/slim/delete_item",
            method : "post",
            data : {
                sid : 'smartgym',
                icd : icd
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: gubun + " " + detail + "상품을 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteKey(props.data.icd)
              },
              {
                label: 'No'
              }
            ]
          });
    }

    useEffect(()=>{
        if(props.mode === "add"){
            setTitle("상품 생성");
        }else if(props.mode === "edit"){
            console.log(props.data);
            setTitle("상품 수정");
            setIcd(props.data.icd);
            setCid(props.data.cid);
            setGubun(props.data.gubun);
            setDetail(props.data.detail);
            setPrice(props.data.price);
            setPriod(props.data.priod);
            setPriority(props.data.priority);
            props.data.unit === "1" ? setUnit(unitOptions[0]) : setUnit(unitOptions[1])
            props.data.state === "1" ? setState(stateOptions[0]) : setState(stateOptions[1])
        }else{
            alert("정상적인 접근이 아닙니다.")
        }
    },[]);

    return(
        <div className="popup">
            <Draggable handle=".handle">
            <div className="popupInner">
            <div className="handle">
            <h3>{title}</h3>
            <FontAwesomeIcon icon={faTimes} onClick={() => props.closePopup("")} className="closeIcon" />
            </div>
            <Table>
            <tbody>
                <tr>
                    <td style={{width:'160px'}}>센터명</td>
                    <td className="td-select">
                    <SelectCenter onSelectedCenter={handleSelectedCenter} defaultValue={cid} width="w-100"/>
                    </td>
                </tr>
                <tr>
                    <td>구분</td>
                    <td>
                    <Form.Control value={gubun} onChange={(e)=> setGubun(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td>상세정보</td>
                    <td>
                    <Form.Control value={detail} onChange={(e)=> setDetail(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td>가격</td>
                    <td>
                    <Form.Control value={price} onChange={(e)=> setPrice(e.target.value)}/>
                    </td>
                </tr>
                <tr>
                    <td>이용기간</td>
                    <td>
                    <Form.Control type="number" value={priod} onChange={(e)=> setPriod(e.target.value)} style={{width : '150px', display : 'inline-block'}}/>
                    <Select
                        name="unit"
                        value={unit}
                        onChange={handleUnitChange}
                        options= {unitOptions}
                        className="ml-1 wd-150 d-inline-block"
                    />
                    </td>
                </tr>
                <tr>
                    <td>노출순위</td>
                    <td>
                    <Form.Control value={priority} onChange={(e)=> setPriority(e.target.value)} placeholder="숫자 2자"/>
                    </td>
                </tr>
                <tr>
                    <td>노출옵션</td>
                    <td>
                    <Select
                        name="state"
                        value={state}
                        onChange={handleStateChange}
                        options= {stateOptions}
                    />
                    </td>
                </tr>
            </tbody>
            </Table>
            {props.mode === "add" || props.mode === "addClient" ? 
            <div className="text-right mr-auto">
            <ButtonStyle mode="add" title="추가" buttonClick={() => handleSubmit()}/>
            </div>
            :
            <div className="text-center mr-auto">
            <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
            <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
            </div>
            }
            {showSearch ? 
            <SearchClientPopup
                closePopup={toggleSearch}
                onSubmit = {onInfoSubmit}
            />
            : null
            }
            </div>
            </Draggable>
        </div>
    )
}

export default ItemPopup;
