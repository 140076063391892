import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import SelectCenter from "../util/SelectCenter";
import ButtonStyle from '../util/ButtonStyle';
import SearchClientPopup from "../util/SearchClientPopup";
import SearchItemPopup from "../util/SearchItemPopup";
import format from 'date-fns/format';
import { useSelector, useDispatch } from 'react-redux';

export default function PurchaseInfoEdit({match}) {
    const pcd = match.params.pcd;
    const [purchaseInfo, setPurchaseInfo] = useState([]);
    const [name, setName] = useState('');
    const [icd, setIcd] = useState('');
    const [itemName, setItemName] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [price1, setPrice1] = useState('');
    const [price2, setPrice2] = useState('');
    const [showClientSearch, setShowClientSearch] = useState(false);
    const [showItemSearch, setShowItemSearch] = useState(false);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [fees, setFees] = useState(0);
    const [itemGubun, setItemGubun] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function getPurchaseDetailInfo(){
        axios({
            url : data.url + "/slim/purchase_detail_info",
            method : "post",
            data : {
                sid : 'smartgym',
                pcd : pcd
            }
        }).then(function (res){
            console.log(res.data[0].method);
            setPurchaseInfo(res.data[0]);
            setSelectedCenter(res.data[0].cid);
            setIcd(res.data[0].icd);
            setName(res.data[0].name);
            setPrice1(res.data[0].price1);
            setPrice2(res.data[0].price2);
            setFees(res.data[0].fees);
            setPaymentType(res.data[0].method);
            var year2 = res.data[0].sdate.substring(0, 4);
            var month2 = res.data[0].sdate.substring(4, 6);
            var day2 = res.data[0].sdate.substring(6, 8);
            setStartDate(new Date(year2 + '-' + month2 + "-" + day2))

            var year3 = res.data[0].edate.substring(0, 4);
            var month3 = res.data[0].edate.substring(4, 6);
            var day3 = res.data[0].edate.substring(6, 8);
            setEndDate(new Date(year3 + '-' + month3 + "-" + day3));

            setItemName(res.data[0].itemName1 +" "+ res.data[0].itemName2);
            setItemGubun(res.data[0].itemGubun);
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleEdit() {
        var formatSdate = format(startDate, 'yyyyMMdd');
        var formatEdate = format(endDate, 'yyyyMMdd');
        var formatSdate2 = format(startDate, 'yyyy-MM-dd 00:00:00');
        var formatEdate2 = format(endDate, 'yyyy-MM-dd 23:59:00');
        console.log(paymentType);

        var index = "CSN"+name+"|SSG"+itemGubun;

        axios({
            url : data.url + "/slim/edit_purchase_info",
            method : "post",
            data : {
                sid : 'smartgym',
                pcd : pcd,
                cid : selectedCenter,
                paymentType : paymentType,
                icd : icd,
                price1 : price1,
                price2 : price2,
                fees : fees,
                sdate : formatSdate,
                edate : formatEdate,
                formatSdate : formatSdate2,
                formatEdate : formatEdate2,
                index : index
            }
        }).then(function (response){
            alert("수정되었습니다.");
            window.location.href = '/purchaseInfo/'+pcd;
        }).catch(function (error){
            console.log(error);
        })
    }

    function handlePrice2Chanege(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value == '' || re.test(event.target.value)) {
            setPrice2(event.target.value);
        }
    }

    function handleStartChange(date) { 
        setStartDate(date)
    }

    function handleEndChange(date) { 
        setEndDate(date)
    }

    function toggleClientSearch() {
        setShowClientSearch(!showClientSearch)
    }

    function toggleItemSearch() {
        setShowItemSearch(!showItemSearch)
    }
    
    function handleSelectedCenter(center){
        setSelectedCenter(center);
    }

    function onClientInfoSubmit(name, mcd){
        setName(name);
    }

    function onItemInfoSubmit(itemInfo){
        setItemName(itemInfo.itemName);
        setPrice1(itemInfo.price1);
        setPrice2(itemInfo.price1);
        setIcd(itemInfo.icd);
        setItemGubun(itemInfo.itemGubun);        
    }

    function handleRadio(event){
        setPaymentType(event.target.value)
    }

    function moveBack() {
        window.history.back();
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getPurchaseDetailInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>구매 정보 수정</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body purchase-info-edit">
                <div className="info-table-left text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} width="w-100" defaultValue={purchaseInfo.cid}/></td>
                        </tr>
                        <tr>
                            <td>고객명</td>
                            <td>
                            <input type="text" className="input-readonly" value={name} readOnly/>
                            </td>
                        </tr>
                        <tr>
                            <td>상품</td>
                            <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>종목</td>
                                        <td>
                                        <input type="text" className="input-readonly" value={itemName} readOnly/>
                                        <Button className="ml-1" onClick={toggleItemSearch} size="sm">검색</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>판매가</td>
                                        <td>
                                        <input type="text" className="input-readonly" value={price1} readOnly/>&nbsp;원
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>구매가</td>
                                        <td>
                                        <input type="text" value={price2} onChange={handlePrice2Chanege}/>&nbsp;원
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>현장 수수료</td>
                                        <td>
                                        <input type="text" value={fees} />&nbsp;원
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>유효기간</td>
                                        <td>
                                        <DatePicker
                                            selected = {startDate}
                                            onChange={handleStartChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            locale="ko"
                                        />
                                        <span>&nbsp;~&nbsp;</span>
                                        <DatePicker
                                            selected = {endDate}
                                            onChange={handleEndChange}
                                            dateFormat="yyyy-MM-dd"
                                            className="form-control"
                                            style={{width:'100px'}}
                                            locale="ko"
                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        <tr>
                            <td>결제 구분</td>
                            <td>
                            <label>
                            <input type="radio" name="radioGroup" value='5' checked={paymentType === "5"} onChange={handleRadio}/>
                             &nbsp;카드(현장)
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="radioGroup" value='6' checked={paymentType === "6"} onChange={handleRadio}/>
                             &nbsp;현금(현장)
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="radioGroup" value='9' checked={paymentType === "9"} onChange={handleRadio}/>
                             &nbsp;PT 회원
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="radioGroup" value='2' checked={paymentType === "2"} onChange={handleRadio}/>
                             &nbsp;현금(계좌)
                            </label>
                            <label>
                            <input type="radio" name="radioGroup" value='1' checked={paymentType === "1"} onChange={handleRadio}/>
                             &nbsp;카드(온라인)
                            </label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
                    <ButtonStyle mode="list" title="뒤로" buttonClick={() => moveBack()}/>
                </div>
                </div>
                </div>
            </div>
            {showClientSearch ? 
            <div className="popup purchase-client">
                <SearchClientPopup
                    closePopup={toggleClientSearch}
                    onSubmit = {onClientInfoSubmit}
                />
            </div>
            : null
            }
            {showItemSearch ? 
            <SearchItemPopup
                closePopup={toggleItemSearch}
                onSubmit = {onItemInfoSubmit}
                cid = {selectedCenter}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}