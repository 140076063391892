import React, { useEffect, useReducer, useState } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import ButtonStyle from '../util/ButtonStyle';
import DaumPostcodePopup from '../util/DaumPostCodePopup';
import { useSelector } from 'react-redux';

const initialState = {
    center1 : '',
    center2 : '',
    phone1 : '',
    phone2 : '',
    phone3 : '',
    address : ''
}

function reducer(state, {field, value}){
    return {
        ...state,
        [field] : value
    }
}

export default function CenterInfoAdd() {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [showPostCode, setShowPostCode] = useState(false);
    const data = useSelector(state => state.data);

    const onChange = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.name == "phone1" || e.target.name == "phone2" || e.target.name == "phone3"){
            if (e.target.value == '' || re.test(e.target.value)) {
                dispatch({field : e.target.name , value : e.target.value})
            }
        }else{
            dispatch({field : e.target.name , value : e.target.value})
        }
    }
    const { center1, center2, phone1, phone2, phone3, address } = state;
    
    function handleAdd(){
        var formatPhone = phone1+""+phone2+""+phone3
        if(!center1 || !center2){
            alert("가맹점명을 입력해주세요.");
            return false;
        }
        if(!address){
            alert("주소를 입력해주세요.");
            return false;
        }
        axios({
            url : data.url + "/slim/add_center_info",
            method : "post",
            data : {
                sid : 'smartgym',
                center1 : center1,
                center2 : center2,
                phone : formatPhone,
                address : address
            }
        }).then(function (response){
            alert("등록되었습니다.");
            window.location.href = '/centerInfo/'+response.data;
        }).catch(function (error){
            console.log(error);
        })
    }

    function openPostCode(){
        setShowPostCode(true)
    }

    function closePopup() {
        setShowPostCode(false)
    }

    function selectAddress(selectedAddress){
        setShowPostCode(false)
        dispatch({ field : "address" , value : selectedAddress})
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>가맹점 추가</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body center-info">
                <div className="info-table-left text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>
                                <input type="text" name="center1" value={center1} onChange={onChange} placeholder="본사명" className="form-control d-inline-block w-25"/>&nbsp;&nbsp;&nbsp;
                                <input type="text" name="center2" value={center2} onChange={onChange} placeholder="지점명" className="form-control d-inline-block w-25"/>
                            </td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>
                                <input type="text" name="phone1" value={phone1} onChange={onChange} maxLength="3" className="form-control d-inline-block w-25"/> -&nbsp;
                                <input type="text" name="phone2" value={phone2} onChange={onChange} maxLength="4" className="form-control d-inline-block w-25"/> -&nbsp;
                                <input type="text" name="phone3" value={phone3} onChange={onChange} maxLength="4" className="form-control d-inline-block w-25"/>
                            </td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>
                                <textarea className="form-control mb-1" value={address} readOnly/>
                                <ButtonStyle mode="list" title="주소 검색" buttonClick={()=> openPostCode()} />
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="add" title="등록" buttonClick={() => handleAdd()}/>
                </div>
                </div>
                </div>
            </div>
            {showPostCode ? 
            <DaumPostcodePopup
            selectAddress = {selectAddress}
            closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}