import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import { faToggleOff, faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DoorIcon from '@material-ui/icons/MeetingRoom';
import PopupComponent from "../util/GatePopup";
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var gateData = '';

let storage = {
    popupMode : '',
    popupData : {}
}

function Gate() {
    const [gateInfo, setGateInfo] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function openPopup(mode, data) {
        setShowPopup(true);
        storage.popupMode = mode;
        storage.popupData = data;
    }

    function closePopup(isUpdated) {
        setShowPopup(false);
        if(isUpdated === "updated"){
            activePage = 1;
            getGateInfo()
        }
    }

    function handleSelectedCenter(cid){
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(gateData,page,pageSize);
        
        totalCount = gateData.length;

        setGateInfo(pageCurrent.data);
    }

    function getGateInfo(){
        axios({
            url : data.url + "/slim/gate_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : data.cid
            }
        }).then(function (res){
            gateData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(gateData,page,pageSize);
            totalCount = gateData.length;
            setGateInfo(pageCurrent.data);
        }).catch(function(error){
            console.log(error);
        })
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getGateInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>출입문 잠금</h3>
            <div className="content-component">
                <div className="content-header">
                <Button className="add-button" onClick={() => openPopup("add", {})}><DoorIcon /> &nbsp; 잠금 추가 </Button>
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
                </div>
                <div className="content-body">
                    <div className="table-header">
                    </div>
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'140px'}}>번호</th>
                                <th style={{width:'430px'}}>잠금 시작 시간</th>
                                <th style={{width:'430px'}}>잠금 종료 시간</th>
                                <th style={{width:'200px'}}>상태</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {gateInfo.map(( item, index ) => {
                            const { txtval, state } = item;
                            const stateClass = (state === "Y") ? 'open' : 'closed';
                            let json = JSON.parse(txtval);
                            let stime = json['stime'];
                            let etime = json['etime'];
                            console.log(item);
                            return(
                                <tr key={index} onClick={() => openPopup("edit", item)} >
                                    <td>{index+1}</td>
                                    <td>{stime}</td>
                                    <td>{etime}</td>
                                    <td>
                                        {state == "Y" ? <FontAwesomeIcon icon={faToggleOn} className={stateClass} style={{fontSize : 20}} />
                                        : <FontAwesomeIcon icon={faToggleOff} className={stateClass} style={{fontSize : 20}} />}
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            {showPopup ? 
            <PopupComponent
                data = {storage.popupData}
                mode = {storage.popupMode}
                closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}

export default Gate;