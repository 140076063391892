import React, { useState } from "react";
import {Button, Form} from 'react-bootstrap';

let filterData = {
    centerKeyword : ''
}

export default function FilterCenter({handleFilter, handleClear }) {
    const [centerKeyword, setCenterKeyword] = useState('');

    function handleCenterKeywordChange(event){
        setCenterKeyword(event.target.value);
        filterData.centerKeyword = event.target.value;
    }

    function Clear(){
        setCenterKeyword('');
        handleClear();
    }
    return (
        <div className="search-rigth-container">
        <Form.Control type="text" id="searchCenter" style={{marginRight:'10px'}} placeholder="센터명" value={centerKeyword} onChange={handleCenterKeywordChange}/>
        <Button size="sm" onClick={()=>handleFilter(filterData)}>검색</Button>
        <Button variant="info" size="sm" className="ml-1" onClick={Clear}>초기화</Button>
        </div>
    )
}