import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form } from 'react-bootstrap';
import ButtonStyle from '../util/ButtonStyle';
import Draggable from 'react-draggable';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCenter from "../util/SelectCenter";
import SearchClientPopup from "./SearchClientPopup";
import { confirmAlert } from 'react-confirm-alert';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import format from 'date-fns/format';
import ko from 'date-fns/locale/ko';
import { useSelector } from 'react-redux';
import moment from 'moment';
registerLocale('ko', ko)

let storage = {
    gubun : ''
}

function SmartKeyPopup(props) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('23:59');
    const [state, setState] = useState({ value : 1, label : 'Y' });
    const [showSearch, setShowSearch] = useState(false);
    const [name, setName] = useState('');
    const [cd, setCd] = useState('');
    const [cid, setCid] = useState('');
    const [selectedCenter, setSelectedCenter] = useState('');
    const [title, setTitle] = useState('');
    const data = useSelector(state => state.data);

    const options = [
        { value : 1, label : 'Y' },
        { value : 0, label : 'N' }
    ]

    function toggleSearch() {
        setShowSearch(!showSearch)
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleStartChange(date) {
        setStartDate(date)
    }

    function handleEndChange(date) {
        setEndDate(date);
    }

    function handleStateChange(selectedOption){
        setState(selectedOption)
    }

    function onInfoSubmit(name, cd, gubun) {
        setName(name);
        setCd(cd);
        storage.gubun = gubun;
    }

    function handleSubmit () {
        if(!cd){
            alert("고객을 선택해 주세요.")
            return false;
        }
        if(!selectedCenter){
            alert("센터를 선택해 주세요.")
            return false;
        }
        if(!startDate){
            alert("시작 날짜를 선택해 주세요.")
            return false;
        }
        if(!endDate){
            alert("종료 날짜를 선택해 주세요.")
            return false;
        }
        const formatStartDate = format(startDate, 'yyyy-MM-dd');
        const formatEndDate = format(endDate, 'yyyy-MM-dd')
        const sdate = formatStartDate + " " + startTime + ":00";
        const edate = formatEndDate + " " + endTime + ":00";

        axios({
            url : data.url + "/slim/addkey",
            method : "post",
            data : {
                sid : 'smartgym',
                gubun : storage.gubun,
                cd : cd,
                cid : selectedCenter,
                sdate : sdate,
                edate : edate,
                state : state.label
            }
        }).then(function (response){
            alert("등록되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleEdit() {
        const formatStartDate = format(startDate, 'yyyy-MM-dd');
        const formatEndDate = format(endDate, 'yyyy-MM-dd')
        const sdate = formatStartDate + " " + startTime + ":00";
        const edate = formatEndDate + " " + endTime + ":00";

        axios({
            url : data.url + "/slim/editKey",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : selectedCenter,
                seq : props.data.seq,
                sdate : sdate,
                edate : edate,
                state : state.label
            }
        }).then(function (response){
            alert("수정되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })

    }

    function deleteKey(seq) {
        axios({
            url : data.url + "/slim/deleteKey",
            method : "post",
            data : {
                sid : 'smartgym',
                seq : seq
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: name + "님의 스마트키를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteKey(props.data.seq)
              },
              {
                label: 'No'
              }
            ]
          });
    }

    useEffect(()=>{
        if(props.mode === "add"){
            setTitle("키 생성");
        }else if(props.mode === "edit"){
            setTitle("키 수정");
            setName(props.data.name);
            setCid(props.data.cid);
            setStartDate(moment(props.data.sdate).toDate());
            setEndDate(moment(props.data.edate).toDate());
            setStartTime(props.data.sdate.substring(11,16));
            setEndTime(props.data.edate.substring(11,16));
            props.data.state === "Y" ? setState(options[0]) : setState(options[1])
        }else if(props.mode === "addClient"){
            setTitle("키 생성");
            setName(props.data.name);
            setCd(props.data.cd);
        }else{
            alert("정상적인 접근이 아닙니다.")
        }
    },[]);

    return(
        <div className="popup">
            <Draggable handle=".handle">
            <div className="popupInner">
            <div className="handle">
            <h3>{title}</h3>
            <FontAwesomeIcon icon={faTimes} onClick={() => props.closePopup("")} className="closeIcon" />
            </div>
            <Table>
            <tbody>
                <tr>
                    <td style={{width:'160px'}}>고객명</td>
                    <td><input type="text" className="input-readonly" value={name} readOnly/>
                    {props.mode === "add" ? 
                    <Button className="ml-1" onClick={toggleSearch} size="sm">검색</Button>
                    : null 
                    }
                    </td>
                </tr>
                <tr>
                    <td>센터명</td>
                    <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} defaultValue={cid} width="w-100"/></td>
                </tr>
                <tr>
                    <td>시작일</td>
                    <td>
                        <DatePicker
                        selected = {startDate}
                        onChange={handleStartChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="ko"
                        showYearDropdown
                        />
                    <Form.Control type="time" className="ml-1" value={startTime} onChange={(e)=> setStartTime(e.target.value)} style={{width:'150px', display:'inline-block'}}/>
                    </td>
                </tr>
                <tr>
                    <td>종료일</td>
                    <td>
                        <DatePicker
                        selected = {endDate}
                        onChange={handleEndChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        style={{width:'100px'}}
                        locale="ko"
                        showYearDropdown
                        />
                        <Form.Control type="time" className="ml-1" value={endTime} onChange={(e)=> setEndTime(e.target.value)} style={{width:'150px', display:'inline-block'}}/>
                    </td>
                </tr>
                <tr>
                    <td>상태</td>
                    <td>
                    <Select
                        name="state"
                        value={state}
                        onChange={handleStateChange}
                        options= {options}
                    />
                    </td>
                </tr>
            </tbody>
            </Table>
            {props.mode === "add" || props.mode === "addClient" ? 
            <div className="text-right mr-auto">
            <ButtonStyle mode="add" title="추가" buttonClick={() => handleSubmit()}/>
            </div>
            :
            <div className="text-center mr-auto">
            <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
            <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
            </div>
            }
            {showSearch ? 
            <SearchClientPopup
                closePopup={toggleSearch}
                onSubmit = {onInfoSubmit}
            />
            : null
            }
            </div>
            </Draggable>
        </div>
    )
}

export default SmartKeyPopup;
