import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import ButtonStyle from '../util/ButtonStyle';
import SelectCenter from "../util/SelectCenter";
import DaumPostcodePopup from '../util/DaumPostCodePopup';
import format from 'date-fns/format';
import { useSelector, useDispatch } from 'react-redux';

var pathOption = [];
var aimOption = [];
// var radioOption = [{value : "1",bolean : true},{value : "2",bolean : false}]

export default function ClientInfoEdit({match}) {
    const mcd = match.params.mcd;
    const [personInfo, setPersonInfo] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [cid, setCid] = useState('');
    const [gender, setGender] = useState('');
    const [address, setAddress] = useState('');
    const [birthday, setBirthday] = useState(new Date("2000-01-01"));
    const [formatPhone, setFormatPhone] = useState('');
    const [path, setPath] = useState({ value : 0, label : '선택'});
    const [aim, setAim] = useState({ value : 0, label : '선택'});
    const [sms, setSms] = useState(false);
    const [push, setPush] = useState(false);
    const [memo, setMemo] = useState('');
    const [showPostCode, setShowPostCode] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    function getClientPersonInfo(){
        axios({
            url : data.url + "/slim/client_person_info",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd
            }
        }).then(function (res){
            console.log(res.data[0]);
            setPersonInfo(res.data[0]);
            setCid(res.data[0].cid);
            setAddress(res.data[0].address);
            if(res.data[0].birthday !== '00000000'){
                var year = res.data[0].birthday.substring(0, 4);
                var month = res.data[0].birthday.substring(4, 6);
                var day = res.data[0].birthday.substring(6, 8);
                setBirthday(new Date(year + '-' + month + "-" + day));
            }

            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,7);
            var num3 = res.data[0].phone.substring(7,11);
            setFormatPhone(num1 + '-' + num2 + "-" + num3);
            
            if(res.data[0].path){
                setPath(pathOption.find(element => element.value === res.data[0].path));
            }
            if(res.data[0].aim){
                setAim(aimOption.find(element => element.value === res.data[0].aim));
            }
            setGender(res.data[0].gender);
            setSms(res.data[0].sms);
            setPush(res.data[0].push);
            if(res.data[0].memo){
                setMemo(res.data[0].memo);
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function getPathOption(){
        axios({
            url : data.url + "/slim/path_option",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(function (res){
            res.data.map((options) => {
                pathOption.push({ value : options.value , label : options.label})
            });
        }).catch(function(error){
            console.log(error);
        })
    }

    function getAimOption(){
        axios({
            url : data.url + "/slim/aim_option",
            method : "post",
            data : {
                sid : 'smartgym'
            }
        }).then(function (res){
            res.data.map((options) => {
                aimOption.push({value : options.value , label : options.label})
            });
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleEdit() {
        var formatBirthday = format(birthday, 'yyyyMMdd');
        var pathValue = '';
        var aimValue = '';
        if(path){
            pathValue = path.value;
        }
        if(aim){
            aimValue = aim.value;
        }
        axios({
            url : data.url + "/slim/edit_client_info",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd,
                cid : selectedCenter,
                birthday : formatBirthday,
                gender : gender,
                address : address,
                path : pathValue,
                aim : aimValue,
                sms : sms,
                push : push,
                memo : memo
            }
        }).then(function (response){
            alert("수정되었습니다.");
            // window.location.reload();
            window.location.href = '/clientInfo/'+mcd;
            
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleBirthdatChange(date){
        setBirthday(date);
    }

    function openPostCode(){
        setShowPostCode(true);
    }

    function closePopup() {
        setShowPostCode(false);
    }

    function handleGenderChange(event){
        setGender(event.target.value);
    }

    function selectAddress(selectedAddress){
        setShowPostCode(false);
        setAddress(selectedAddress);
    }

    function handlePathChange(event){
        setPath(pathOption.find(element => element.value === event.value));
    }

    function handleAimChange(event){
        setAim(aimOption.find(element => element.value === event.value));
    }

    function handleMemoChange(event){
        setMemo(event.target.value);
    }

    function handleSmsChange(event){
        setSms(event.target.value);
    }

    function handlePushChange(event){
        setPush(event.target.value);
    }

    function moveBack() {
        window.history.back();
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getPathOption();
            getAimOption();
            getClientPersonInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>고객 정보 수정</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body client-info-edit">
                <div className="info-table-left text-left">
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>가맹점</td>
                                <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} defaultValue={cid} width="w-100"/></td>
                            </tr>
                            <tr>
                                <td>고객명</td>
                                <td>{personInfo.name}</td>
                            </tr>
                            <tr>
                                <td>생년월일</td>
                                <td>
                                    <DatePicker
                                    selected = {birthday}
                                    onChange={handleBirthdatChange}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control"
                                    locale="ko"
                                    showYearDropdown
                                    />
                                    &nbsp;&nbsp;
                                    <div onChange={handleGenderChange} className="d-inline-block">
                                    <input type="radio" name="gender" value="1" checked={gender === "1"}/> 남자&nbsp;&nbsp;
                                    <input type="radio" name="gender" value="2" checked={gender === "2"}/> 여자
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>휴대전화</td>
                                <td>
                                    <input type="text" className="input-readonly form-control w-50 mb-1" value={formatPhone} readOnly/>
                                </td>
                            </tr>
                            <tr>
                                <td>주소</td>
                                <td>
                                <textarea className="form-control mb-1" value={address} readOnly/>
                                <input type="button" onClick={()=> openPostCode()} value="주소 검색"></input>
                                </td>
                            </tr>	
                            <tr>
                                <td>고객 방문경로</td>
                                <td style={{overflow : "initial"}}>
                                <Select 
                                    value ={path}
                                    placeholder="방문경로"
                                    onChange={handlePathChange}
                                    options = {pathOption}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>운동목적</td>
                                <td style={{overflow : "initial"}}>
                                <Select 
                                    value ={aim}
                                    placeholder="운동목적"
                                    onChange={handleAimChange}
                                    options = {aimOption}
                                />
                                </td>
                            </tr>
                            <tr>
                                <td>마케팅 수신 동의</td>
                                <td>
                                    SMS&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sms" value="1" checked={sms === "1"} onChange={handleSmsChange}/> 동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="sms" value="2" checked={sms === "2"} onChange={handleSmsChange}/> 비동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <br />
                                    PUSH&nbsp;&nbsp;
                                    <input type="radio" name="push" value="1" checked={push === "1"} onChange={handlePushChange}/> 동의&nbsp;&nbsp;&nbsp;&nbsp;
                                    <input type="radio" name="push" value="2" checked={push === "2"} onChange={handlePushChange}/> 비동의&nbsp;&nbsp;&nbsp;&nbsp;
                                </td>
                            </tr>
                            <tr>
                                <td>회원메모</td>
                                <td>
                                    <div className="memo">
                                    <textarea className="form-control" value={memo} onChange={handleMemoChange}>
                                    </textarea>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="button-container">
                        <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
                        <ButtonStyle mode="list" title="뒤로" buttonClick={() => moveBack()}/>
                    </div>
                </div>
                </div>
            </div>
            {showPostCode ? 
            <DaumPostcodePopup
            selectAddress = {selectAddress}
            closePopup={closePopup}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}