import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Table, Form } from 'react-bootstrap';
import ButtonStyle from '../util/ButtonStyle';
import Draggable from 'react-draggable';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCenter from "../util/SelectCenter";
import { confirmAlert } from 'react-confirm-alert';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from  "react-datepicker";
import format from 'date-fns/format';
import ko from 'date-fns/locale/ko';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { addDays, subDays } from 'date-fns'
registerLocale('ko', ko)

function GatePopup(props) {
    const options = [
        { value : 'Y', label : '사용' },
        { value : 'N', label : '사용 안함' }
    ]

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [startTime, setStartTime] = useState('00:00');
    const [endTime, setEndTime] = useState('23:59');
    const [state, setState] = useState(options[0]);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [title, setTitle] = useState('');
    const data = useSelector(state => state.data);

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleStartChange(date) {
        setStartDate(date)
    }

    function handleEndChange(date) {
        setEndDate(date);
    }

    function handleStateChange(selectedOption){
        setState(selectedOption)
    }

    function handleStartDatePlus(){
        setStartDate(addDays(startDate, 1))
    }

    function handleStartDateMinus(){
        setStartDate(subDays(startDate, 1))
    }

    function handleEndDatePlus(){
        setEndDate(addDays(endDate, 1))
    }

    function handleEndDateMinus(){
        setEndDate(subDays(endDate, 1))
    }

    // gate 추가 및 복사
    function handleSubmit () {
        if(!startDate){
            alert("시작 날짜를 선택해 주세요.")
            return false;
        }
        if(!endDate){
            alert("종료 날짜를 선택해 주세요.")
            return false;
        }
        if(!startTime){
            alert("시작 시간을 입력해 주세요.")
            return false;
        }
        if(!endTime){
            alert("종료 시간을 입력해 주세요.")
            return false;
        }
        const formatStartDate = format(startDate, 'yyyy-MM-dd');
        const formatEndDate = format(endDate, 'yyyy-MM-dd')
        const stime = formatStartDate + " " + startTime;
        const etime = formatEndDate + " " + endTime;
        let txtval = '{ "stime" : "' +stime+ '", "etime" : "' +etime+ '" }'

        axios({
            url : data.url + "/slim/add_gate_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : data.cid,
                txtval : txtval,
                state : state.value
            }
        }).then(function (response){
            alert("완료되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleEdit () {
        if(!startDate){
            alert("시작 날짜를 선택해 주세요.")
            return false;
        }
        if(!endDate){
            alert("종료 날짜를 선택해 주세요.")
            return false;
        }
        if(!startTime){
            alert("시작 시간을 입력해 주세요.")
            return false;
        }
        if(!endTime){
            alert("종료 시간을 입력해 주세요.")
            return false;
        }
        const formatStartDate = format(startDate, 'yyyy-MM-dd');
        const formatEndDate = format(endDate, 'yyyy-MM-dd')
        const stime = formatStartDate + " " + startTime;
        const etime = formatEndDate + " " + endTime;
        let txtval = '{ "stime" : "' +stime+ '", "etime" : "' +etime+ '" }'

        axios({
            url : data.url + "/slim/edit_gate_info",
            method : "post",
            data : {
                sid : 'smartgym',
                seq : props.data.seq,
                txtval : txtval,
                state : state.value
            }
        }).then(function (response){
            alert("완료되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function deleteGate(seq) {
        axios({
            url : data.url + "/slim/delete_gate_info",
            method : "post",
            data : {
                sid : 'smartgym',
                seq : seq
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            props.closePopup("updated");
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: "잠금 정보를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteGate(props.data.seq)
              },
              {
                label: 'No'
              }
            ]
          });
    }

    useEffect(()=>{
        if(props.mode === "add"){
            setTitle("잠금 정보 생성");
        }else if(props.mode === "edit"){
            let txtval = props.data.txtval;
            let json = JSON.parse(txtval);
            let stime = json['stime'];
            let etime = json['etime'];
            setTitle("잠금 정보 수정");
            setStartDate(moment(stime).toDate());
            setEndDate(moment(etime).toDate());
            setStartTime(stime.substring(11,16));
            setEndTime(etime.substring(11,16));
            props.data.state === "Y" ? setState(options[0]) : setState(options[1])
        }else{
            alert("정상적인 접근이 아닙니다.")
        }
    },[]);

    return(
        <div className="popup">
            <Draggable handle=".handle">
            <div className="popupInner">
            <div className="handle">
            <h3>{title}</h3>
            <FontAwesomeIcon icon={faTimes} onClick={() => props.closePopup("")} className="closeIcon" />
            </div>
            <Table>
            <tbody>
                <tr>
                    <td style={{width: '160px'}}>센터명</td>
                    <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} defaultValue={data.cid} width="w-100"/></td>
                </tr>
                <tr>
                    <td>시작일</td>
                    <td>
                        <DatePicker
                        selected = {startDate}
                        onChange={handleStartChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        locale="ko"
                        showYearDropdown
                        />
                    <Form.Control type="time" className="ml-1" value={startTime} onChange={(e)=> setStartTime(e.target.value)} style={{width:'150px', display:'inline-block'}}/>
                    <ButtonStyle mode="minus" title="-1일" buttonClick={() => handleStartDateMinus()}/>
                    <ButtonStyle mode="plus" title="+1일" buttonClick={() => handleStartDatePlus()}/>
                    </td>
                </tr>
                <tr>
                    <td>종료일</td>
                    <td>
                        <DatePicker
                        selected = {endDate}
                        onChange={handleEndChange}
                        dateFormat="yyyy-MM-dd"
                        className="form-control"
                        style={{width:'100px'}}
                        locale="ko"
                        showYearDropdown
                        />
                        <Form.Control type="time" className="ml-1" value={endTime} onChange={(e)=> setEndTime(e.target.value)} style={{width:'150px', display:'inline-block'}}/>
                        <ButtonStyle mode="minus" title="-1일" buttonClick={() => handleEndDateMinus()}/>
                        <ButtonStyle mode="plus" title="+1일" buttonClick={() => handleEndDatePlus()}/>
                    </td>
                </tr>
                <tr>
                    <td>상태</td>
                    <td>
                    <Select
                        name="state"
                        value={state}
                        onChange={handleStateChange}
                        options= {options}
                    />
                    </td>
                </tr>
            </tbody>
            </Table>
            {props.mode === "add" || props.mode === "addClient" ? 
            <div className="text-right mr-auto">
            <ButtonStyle mode="add" title="추가" buttonClick={() => handleSubmit()}/>
            </div>
            :
            <div className="text-center mr-auto">
            <ButtonStyle mode="add" title="복사" buttonClick={() => handleSubmit()}/>
            <ButtonStyle mode="edit" title="수정" buttonClick={() => handleEdit()}/>
            <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
            </div>
            }
            </div>
            </Draggable>
        </div>
    )
}

export default GatePopup;
