import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import ButtonStyle from '../util/ButtonStyle';
import { confirmAlert } from 'react-confirm-alert';
import NumberFormat from 'react-number-format';
import paymentData from '../util/paymentData.json';
import { useSelector } from 'react-redux';

export default function PurchaseInfo({match}) {
    const pcd = match.params.pcd;
    const [purchaseInfo, setPurchaseInfo] = useState([]);
    const data = useSelector(state => state.data);

    function getPurchaseDetailInfo(){
        axios({
            url : data.url + "/slim/purchase_detail_info",
            method : "post",
            data : {
                sid : 'smartgym',
                pcd : pcd
            }
        }).then(function (res){
            var temp = res.data[0];

            var year1 = res.data[0].purchaseDate.substring(0, 4);
            var month1 = res.data[0].purchaseDate.substring(4, 6);
            var day1 = res.data[0].purchaseDate.substring(6, 8);
            var hour1 = res.data[0].purchaseDate.substring(8, 10);
            var min1 = res.data[0].purchaseDate.substring(10, 12);
            var sec1 = res.data[0].purchaseDate.substring(12, 14);
            temp["formatPurchaseDate"] = year1 + '-' + month1 + "-" + day1 +" " + hour1 + ":"+ min1 + ":"+ sec1;

            var year2 = res.data[0].sdate.substring(0, 4);
            var month2 = res.data[0].sdate.substring(4, 6);
            var day2 = res.data[0].sdate.substring(6, 8);
            temp["formatSdate"]= year2 + '-' + month2 + "-" + day2;

            var year3 = res.data[0].edate.substring(0, 4);
            var month3 = res.data[0].edate.substring(4, 6);
            var day3 = res.data[0].edate.substring(6, 8);
            temp["formatEdate"] = year3 + '-' + month3 + "-" + day3;
            temp["paymentValue"] = '';
            if(res.data[0].method){
                if(paymentData.find(element => element.value === res.data[0].method)){
                    temp["paymentValue"] = paymentData.find(element => element.value === res.data[0].method).name;
                }
            }
            setPurchaseInfo(temp);
        }).catch(function(error){
            console.log(error);
        })
    }

    function deletePurchase(){
        axios({
            url : data.url + "/slim/delete_purchase_info",
            method : "post",
            data : {
                sid : 'smartgym',
                pcd : pcd
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            window.location.href = '/purchase';
        }).catch(function (error){
            console.log(error);
        })
    }

    function moveList(){
        window.location.href = '/purchase';
    }

    function moveEdit(){
        window.location.href = '/purchaseInfoEdit/'+pcd;
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: purchaseInfo.name + "님의 구매 정보를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deletePurchase()
              },
              {
                label: 'No'
              }
            ]
          });
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            getPurchaseDetailInfo(); 
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>구매 상세정보</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body purchase-info">
                <div className="info-table-100 text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>{purchaseInfo.center +" "+ purchaseInfo.location}</td>
                        </tr>
                        <tr>
                            <td>고객명</td>
                            <td>{purchaseInfo.name}</td>
                        </tr>
                        <tr>
                            <td>상품</td>
                            <td>
                                <table className="item-table">
                                    <tbody>
                                        <tr>
                                            <td>종목</td>
                                            <td>{purchaseInfo.itemName1 + " " + purchaseInfo.itemName2}</td>
                                        </tr>
                                        <tr>
                                            <td>구매가</td>
                                            <td><NumberFormat value={purchaseInfo.price2} displayType={'text'} thousandSeparator={true} suffix={'원'} /></td>
                                        </tr>
                                        <tr>
                                            <td>유효기간</td>
                                            <td>{purchaseInfo.formatSdate + " ~ " +purchaseInfo.formatEdate}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>결제</td>
                            <td>
                                <table className="purchase-table">
                                    <tbody>
                                        <tr>
                                            <td>구매일자</td>
                                            <td>{purchaseInfo.formatPurchaseDate}</td>
                                        </tr>
                                        <tr>
                                            <td>결제구분</td>
                                            <td>{purchaseInfo.paymentValue}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="list" title="목록" buttonClick={() => moveList()}/>
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => moveEdit()}/>
                    <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
                </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}