import React,{useEffect,useState} from "react";
import { BrowserRouter as Link } from "react-router-dom";
import logo from "../img/logo_white.png";
import styled from "styled-components";
import Button from 'react-bootstrap/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useSelector } from 'react-redux';
import axios from 'axios';
import DecodeData from '../util/DecodeData';

const TopNavContainer = styled.div`
    line-height:40px;
    & a {
        color: rgba(255, 255, 255, 0.5);
        display: inline-block;
        line-height: 40px;
        height: 40px;
    }

    & .logout {
        cursor: pointer;
    }
`;

const OpenButtonStyle = styled(Button)`    
    margin-right:5px;
`;

const LogoutStyle = styled(Button)`
`;

let cid = '';

function logout() {
    localStorage.setItem("access_token", "");
    localStorage.setItem("refresh_token", "");
    window.location.href = "/login";
}

const open_door_post = (data) => {
    // alert(data.cid);
    // alert(data.url);
    
    let access_token = localStorage.getItem('access_token'); 
    const target = data.url + '/slim/token/admin_dooropen';
    axios.post(target,{
        sid:'smartgym',
        cid:data.cid,
        access_token:access_token
    },{timeout:3000})
    .then(()=>alert('출입문열기 성공'))
    .catch(error=>alert(error));
}

const open_door_click = (data) => {    
    confirmAlert({
        title:'Confirm to submit',
        message: '출입문을 개방하시겠습니까?',
        buttons:[
            {label:'예', onClick:()=> open_door_post(data)},
            {label:'아니오'}
        ]
    });
}  

function TopNav() {     
    
    const [DoorButton, setDoorButton] = useState(false);
    const data = useSelector(state => state.data);   

    // 최고 관리자는 문열기 버튼 안보이도록
    // (비상 개방은 지점 관리자가 관리)
    useEffect(()=>{
        DecodeData(data.url)
        .then( result => {
            const level = result.level;
            if(typeof(level) === 'number' && level === 100) {
              setDoorButton(true);
            }
        })
        .catch( error => alert(error) )
    },[]);    

    return (
        <TopNavContainer
            className="fixed-top"
            style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "50px",
                backgroundColor: "#313338",
            }}
        >
            <div>
                <a className="nav-tab"></a>
                <Link className="navbar-brand" to={"/"}>
                    <img src={logo} alt="logo" />
                </Link>
            </div>
            <div>
			
				{ DoorButton ?
                <OpenButtonStyle type="button" variant="secondary" size="sm" onClick={()=>open_door_click(data)}>
                    출입문열기					
                </OpenButtonStyle>
				: null }

                <LogoutStyle type="button" variant="secondary" onClick={logout} size="sm">
                   로그아웃
                </LogoutStyle>              
                
            </div>
        </TopNavContainer>
    );
}

export default TopNav;
