import React from "react";
import styled from "styled-components";

const ButtonStyleContainer = styled.div`
    height : 40px;
    display:inline-block;
    margin: 0 3px;

    >div{
        padding : 3px 10px;
        cursor: pointer;
    }
    
    .add{
        background-color:#1e90d8;
        color: #fff;
    }

    .list{
        border : 1px solid #969b9e;
        color : #969b9e;
    }

    .edit{
        border : 1px solid #bcc0c2;
        color : #fff;
        background-color : #7b7b7b;
    }

    .delete{
        border : 1px solid #f90e1b;
        color: #f90e1b;
    }

    .check{
        border : 1px solid #7b7b7b;
        color: #7b7b7b;
    }

    .plus{
        background-color:#52b84a;
        color: #fff;
    }

    .minus{
        background-color:#f90e1b;
        color: #fff;
    }
`
export default function ButtonStyle({mode, title, buttonClick}) {
    return (
        <ButtonStyleContainer>
            <div className={mode} onClick={buttonClick}>{title}</div>
        </ButtonStyleContainer>
    )
}