import React, { useState, useEffect } from "react";
import axios from 'axios';
import {Button, Table} from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import Filter from '../util/Filter';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { format } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var teacherData = '';
var clear = false;
var name = '';
var phone = '';
var filterMode = {
    name : true,
    phone : true
}

let storage = {
    nameKeyword : '',
    phoneKeyword : '',
    teacherCount : 0
}

export default function Teacher() {
    const [teacher, setTeacher] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handleClear(){
        storage.nameKeyword = '';
        storage.phoneKeyword = '';
        clear = true;
        activePage = 1;
        getTeacher();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        storage.phoneKeyword = filterData.phoneKeyword
        activePage = 1;
        getTeacher();
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(teacherData,page,pageSize);
        
        totalCount = teacherData.length;

        setTeacher(pageCurrent.data);
    }

    function getTeacher(){
        if(clear === true){
            name = ''
            phone = '';
        }else{
            name = storage.nameKeyword;
            phone = storage.phoneKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/teacher",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                phoneKeyword : phone
            }
        }).then(function (res){
            teacherData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(teacherData,page,pageSize);
            
            totalCount = teacherData.length;

            setTeacher(pageCurrent.data)
            clear = false;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getTeacherCount(){
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/teacher_count",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
            }
        }).then(function (res){
            storage.teacherCount = res.data[0].count;
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClick(tcd){
        window.location.href = '/teacherInfo/'+tcd;
    }

    function handleAdd(){
        window.location.href = '/teacherInfoAdd'
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            console.log(decodeResult);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getTeacherCount();
            getTeacher();
            activePage = 1;
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();   
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getTeacherCount();
            getTeacher();
            activePage = 1;
        }
    },[selectedCenter]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3 style={{display : "inline-block"}}>강사 관리</h3>
            <span style={{float : "right", paddingTop:"10px"}}>총 강사 수 : {storage.teacherCount}</span>
            <div className="content-component">
                <div className="content-header">
                <Button className="add-button" onClick={() => handleAdd()}><PersonAddIcon /> &nbsp; 강사 추가 </Button>
                <SelectCenter 
                    onSelectedCenter={handleSelectedCenter}
                />
                </div>
                <div className="content-body">
                    <div className="table-header">
                        <div></div>
                        <div style={{display : "inline-flex"}}>
                        <Filter 
                            handleFilter = {handleFilter}   
                            handleClear = {handleClear}
                            mode = {filterMode}
                        />
                        </div>
                    </div>
                    <div className="table-body cursor">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'120px'}}>이름</th>
                                <th style={{width:'350px'}}>연락처</th>
                                <th style={{width:'350px'}}>가맹점</th>
                                <th style={{width:'380px'}}>등록일</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0 && loaded === 1 ) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {teacher.map(( teacher, index ) => {
                            const { tcd, name, phone, center, location, regdate } = teacher;
                            var formatRegdate = regdate.substring(0,16);

                            return(
                                <tr key={index} onClick={() => handleClick(tcd)} >
                                    <td>{name}</td>
                                    <td>{phone}</td>
                                    <td>{center + " " +location}</td>
                                    <td className="td-regdate">{formatRegdate}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}