import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCenter from "./SelectCenter";
import Filter from '../util/Filter';
import Draggable from 'react-draggable';
import { useSelector } from 'react-redux';

var totalCount = 0; 
var clear = false;
var name = '';
var filterMode = {
    name : true,
    phone : false
}

let storage = {
    nameKeyword : ''
}

function SearchTeacherPopup(props) {
    const [teacherInfo, setTeacherInfo] = useState([]);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const data = useSelector(state => state.data);

    function getTeacher(){
        if(clear === true){
            name = ''
        }else{
            name = storage.nameKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/user_teacher",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name
            }
        }).then(function (response){
            totalCount = response.data.length;
            setTeacherInfo(response.data);
            clear = false;
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClear(){
        storage.nameKeyword = '';
        clear = true;
        getTeacher();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        getTeacher();
    }

    function handleClick(teacherInfo){
        props.closePopup();
        props.onSubmit(teacherInfo.name, teacherInfo.tcd);
    }

    function handleSelectedCenter(centerId){
        setSelectedCenter(centerId);
    }

    useEffect(()=>{
        getTeacher();
    },[selectedCenter]);

    return(
        <div className="popup teacher">
            <Draggable handle=".handle">
            <div className="searchPopupInner">
            <p>강사명 검색</p>
            <FontAwesomeIcon icon={faTimes} onClick={props.closePopup} className="closeIcon" />
            <SelectCenter 
                onSelectedCenter={handleSelectedCenter}
            />
            <div className="search-popup-container">
            <Filter 
                handleFilter = {handleFilter} 
                handleClear = {handleClear}
                mode = {filterMode}
            />
            </div>
            <Table hover className="scrolltbody">
                <thead>
                <tr>
                    <th>강사명</th>
                    <th>전화번호</th>
                    <th>이메일</th>
                </tr>
                </thead>
                <tbody>
                {(totalCount === 0) &&
                        <tr>
                            <td colSpan='20' style={{width:'540px'}}>
                                데이터가 없습니다.
                            </td>
                        </tr>
                }
                {teacherInfo.map((teacherInfo, index) => {
                    const { name, phone, email} = teacherInfo;
                    return(
                        <tr key={index} onClick={()=>handleClick(teacherInfo)}>
                            <td>{name}</td>
                            <td>{phone}</td>
                            <td>{email}</td>
                        </tr>
                    )
                })}
                {Number(totalCount) > 100 &&
                    <tr>
                    <td colSpan="20" className="bg-light text-center">...</td>
                    </tr>
                }
                </tbody>
            </Table>
            </div>
            </Draggable>
        </div>
    )
}

export default SearchTeacherPopup;
