import axios from 'axios';

let refresh_token = '';
let access_token = ' ';
let is_access_token = '';
let result = '';
let host = '';

async function check_access_token(){
    access_token = localStorage.getItem('access_token');
    if(access_token === null || access_token === '') return 'N';

    try {
        result = await axios({
            url : host + "/slim/token/checkAccessToken",
            method : "post",
            data : {
              sid : "smartgym",
              access_token : access_token
            }
        });
        return result.data.ret;
    } catch (error) {
      throw error;
    } 
} 

async function create_access_token() {
    try { 
        result = await axios({
            url : host + "/slim/token/createAccessToken",
            method : "post",
            data : {
                sid : "smartgym",
                refresh_token : refresh_token
            }
        });
        return result.data.access_token;
    } catch (error) {
      throw error;
    }  
  } 

export default async function LoginCheck(url){
    host = url;
    access_token = localStorage.getItem('access_token');
    refresh_token = localStorage.getItem('refresh_token');
    
    if(refresh_token === null || refresh_token === '') return 'N';

    is_access_token = await check_access_token();

    if(is_access_token === 'Y') {
        console.log('TAG: 로그인 성공')
        return "Y";
    }

    if(is_access_token === "N") {
        access_token = await create_access_token();
        if(access_token !== '' && access_token !== undefined)
        {
            console.log('TAG: 자동로그인')
            localStorage.setItem('access_token', access_token);
            return "Y";
        }
        else 
        {
          return "N";
        }
    }
}