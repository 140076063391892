import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import { format } from 'date-fns';
import ButtonStyle from "../util/ButtonStyle";
import { confirmAlert } from 'react-confirm-alert';
import { useSelector, useDispatch } from 'react-redux';

let initialState = {
    center : '',
    location : '',
    name : ''
}

export default function TeacherInfo({match}) {
    const tcd = match.params.tcd;
    const [personInfo, setPersonInfo] = useState(initialState);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    function getTeacherPersonInfo(){
        console.log()
        axios({
            url : data.url + "/slim/teacher_person_info",
            method : "post",
            data : {
                sid : 'smartgym',
                tcd : tcd
            }
        }).then(function (res){
            var temp = res.data[0];

            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,7);
            var num3 = res.data[0].phone.substring(7,11);
            temp["formatPhone"] = num1 + '-' + num2 + "-" + num3;

            setPersonInfo(temp);
        }).catch(function(error){
            console.log(error);
        })
    }

    function deleteTeacher(){
        axios({
            url : data.url + "/slim/delete_teacher_info",
            method : "post",
            data : {
                sid : 'smartgym',
                tcd : tcd
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            window.location.href = '/teacher';
        }).catch(function (error){
            console.log(error);
        })
    }

    function moveList(){
        window.location.href = '/teacher';
    }

    function moveEdit(){
        window.location.href = '/teacherInfoEdit/'+tcd;
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: personInfo.name + "님의 정보를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteTeacher()
              },
              {
                label: 'No'
              }
            ]
          });
    }

    async function main() {
        let result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            console.log(decodeResult);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getTeacherPersonInfo();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <div className="content-component" style={{border:"none"}}>
                <div className="content-header">
                </div>
                <div className="content-body teacher-info">
                <div className="info-table-left text-left">
                <h5>상세정보</h5>
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>{personInfo.center +" "+ personInfo.location}</td>
                        </tr>
                        <tr>
                            <td>강사명</td>
                            <td>{personInfo.name}</td>
                        </tr>
                        <tr>
                            <td>휴대전화</td>
                            <td>{personInfo.formatPhone}</td>
                        </tr>
                        <tr>
                            <td>가입일자</td>
                            <td>{personInfo.regdate}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="list" title="목록" buttonClick={() => moveList()}/>
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => moveEdit()}/>
                    <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
                </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}