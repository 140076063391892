import React, { useState, useEffect, useReducer } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import Select from 'react-select';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import SelectCenter from "../util/SelectCenter";
import ButtonStyle from '../util/ButtonStyle';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
    name : '',
    phone1 : '',
    phone2 : '',
    phone3 : '',
    email : ''
}

function reducer(state, {field, value}){
    return {
        ...state,
        [field] : value
    }
}

export default function TeacherInfoAdd() {
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [loaded, setLoaded] = useState(0);
    const [selectedCenter, setSelectedCenter] = useState('');
    const [duplicateChecked, setDuplicatedChecked] = useState(false);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    const onChange = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.name == "phone1" || e.target.name == "phone2" || e.target.name == "phone3"){
            if (e.target.value == '' || re.test(e.target.value)) {
                dispatchState({field : e.target.name , value : e.target.value})
            }
        }else{
            dispatchState({field : e.target.name , value : e.target.value})
        }
    }
    const { name, phone1, phone2, phone3, email } = state;

    function handleAdd() {
        if(!selectedCenter){
            alert("센터를 선택해 주세요.")
            return false;
        }
        if(!name){
            alert("이름을 입력해 주세요.")
            return false;
        }
        // 휴대전화 중복체크
        if(!duplicateChecked){
            alert("휴대폰 중복체크를 해주세요.")
            return false;
        }
        var formatPhone = phone1+""+phone2+""+phone3;
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }
        axios({
            url : data.url + "/slim/add_teacher_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                name : name,
                phone : formatPhone,
                pw : phone3
            }
        }).then(function (response){
            alert("등록 되었습니다.");
            window.location.href = '/teacherInfo/'+response.data;
        }).catch(function (error){
            console.log(error);
        })
    }

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function duplicateCheck(){
        var formatPhone = phone1+""+phone2+""+phone3;
        axios({
            url : data.url + "/slim/phone_dupe",
            method : "post",
            data : {
                sid : 'smartgym',
                phone : formatPhone
            }
        }).then(function (response){
            if(response.data.ret === "Y"){
                alert("사용가능한 번호입니다.")
                setDuplicatedChecked(true);
            }else{
                alert(response.data.msg)
            }
        }).catch(function (error){
            console.log(error);
        })
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            console.log(data);
            setLoaded(1);
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>강사 추가</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body teacher-info-add">
                <div className="info-table-left text-left">
                    <Table bordered>
                        <tbody>
                            <tr>
                                <td>가맹점</td>
                                <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} width="w-100"/>
                                </td>
                            </tr>
                            <tr>
                                <td>강사명</td>
                                <td>
                                    <input type="text" name="name" value={name} onChange={onChange} className="form-control w-25" />
                                </td>
                            </tr>
                            <tr>
                                <td>휴대전화</td>
                                <td>
                                    {duplicateChecked ?
                                        <div>
                                            <input type="text" name="phone1" maxLength="3" className="form-control d-inline-block w-25" value={phone1} onChange={onChange} readOnly/> -&nbsp;
                                            <input type="text" name="phone2" maxLength="4" className="form-control d-inline-block w-25" value={phone2} onChange={onChange} readOnly/> -&nbsp;
                                            <input type="text" name="phone3" maxLength="4" className="form-control d-inline-block w-25" value={phone3} onChange={onChange} readOnly/>
                                        </div>
                                        :
                                        <div>
                                            <input type="text" name="phone1" maxLength="3" className="form-control d-inline-block w-25" value={phone1} onChange={onChange}/> -&nbsp;
                                            <input type="text" name="phone2" maxLength="4" className="form-control d-inline-block w-25" value={phone2} onChange={onChange}/> -&nbsp;
                                            <input type="text" name="phone3" maxLength="4" className="form-control d-inline-block w-25" value={phone3} onChange={onChange}/>
                                            <ButtonStyle mode="check" title="중복체크" buttonClick={() => duplicateCheck()}/>
                                        </div>
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="button-container">
                        <ButtonStyle mode="add" title="등록" buttonClick={() => handleAdd()}/>
                    </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}