import React, { useState, useEffect, useReducer } from "react";
import axios from 'axios';
import { Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import SelectCenter from "../util/SelectCenter";
import ButtonStyle from '../util/ButtonStyle';
import SearchClientPopup from "../util/SearchClientPopup";
import SearchItemPopup from "../util/SearchItemPopup";
import format from 'date-fns/format';
import { addDays, addMonths, subDays } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';

const initialState = {
    mcd : '',
    name : '',
    icd : '',
    itemName : '',
    startDate : null,
    endDate : null,
    price1 : '',
    price2 : '',
    paymentType : '',
    itemGubun : ''
}

function reducer(state, {field, value}){
    return {
        ...state,
        [field] : value
    }
}

export default function PurchaseInfoAdd() {
    const [state, dispatchState] = useReducer(reducer, initialState);
    const [loaded, setLoaded] = useState(0);
    const [showClientSearch, setShowClientSearch] = useState(false);
    const [showItemSearch, setShowItemSearch] = useState(false);
    const [selectedCenter, setSelectedCenter] = useState('');
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();
    
    const onChange = (e) => {
        const re = /^[0-9\b]+$/;
        if(e.target.name === "price2"){
            if (e.target.value === '' || re.test(e.target.value)) {
                dispatchState({field : e.target.name , value : e.target.value})
             }
        }else{
            dispatchState({field : e.target.name , value : e.target.value})
        }
    }
    const { mcd, name, icd, itemName, startDate, endDate, price1, price2, paymentType, itemGubun } = state;

    function handleAdd(){
        if(!mcd){
            alert("고객을 선택해 주세요.")
            return false;
        }
        if(!icd){
            alert("상품을 선택해주세요.")
            return false;
        }
        if(!price2){
            alert("구매가를 정확하게 입력해주세요.")
            return false;
        }
        if(paymentType === ''){
            alert("결제 방법을 선택해 주세요.")
            return false;
        }

        var formatSdate = format(startDate, 'yyyyMMdd');
        var formatEdate = format(endDate, 'yyyyMMdd');
        var formatSdate2 = format(startDate, 'yyyy-MM-dd 00:00:00');
        var formatEdate2 = format(endDate, 'yyyy-MM-dd 23:59:00');

        var index = "CSN"+name+"|SSG"+itemGubun;

        axios({
            url : data.url + "/slim/add_purchase_info",
            method : "post",
            data : {
                sid : 'smartgym',
                mcd : mcd,
                icd : icd,
                sdate : formatSdate,
                edate : formatEdate,
                paymentType : paymentType,
                price1 : price1,
                price2 : price2,
                cid : selectedCenter,
                formatSdate : formatSdate2,
                formatEdate : formatEdate2,
                index : index
            }
        }).then(function (response){
            alert("등록되었습니다.");
            window.location.href = '/purchaseInfo/'+response.data;
        }).catch(function (error){
            console.log(error);
        })
    } 

    function handleStartChange(date) {
        dispatchState({field : "startDate" , value : date})
    }

    function toggleClientSearch() {
        setShowClientSearch(!showClientSearch)
    }

    function toggleItemSearch() {
        if(!selectedCenter){
            alert("센터를 선택해주세요.")
            return false;
        }
        if(startDate == null){
            alert("이용시작일을 선택해주세요.")
            return false;
        }
        setShowItemSearch(!showItemSearch)
    }
    
    function handleSelectedCenter(center){
        setSelectedCenter(center);
        dispatchState({field : "startDate" , value : null})
    }

    function onClientInfoSubmit(name, mcd){
        dispatchState({field : "name" , value : name})
        dispatchState({field : "mcd" , value : mcd})
    }

    function onItemInfoSubmit(itemInfo){
        console.log(itemInfo.group + " "+ itemInfo.itemName);
        dispatchState({field : "itemName" , value : itemInfo.group + " "+ itemInfo.itemName})
        dispatchState({field : "price1" , value : itemInfo.price1})
        dispatchState({field : "price2" , value : itemInfo.price1})
        dispatchState({field : "icd" , value : itemInfo.icd})
        dispatchState({field : "itemGubun" , value : itemInfo.itemGubun})
        var tempDate1 = '';
        var tempDate2 = '';
        if(itemInfo.priodGubun === "1"){
            dispatchState({field : "endDate" , value : addDays(startDate, itemInfo.priod)})
        }else{
            tempDate1 = addMonths(startDate, itemInfo.priod)
            tempDate2 = subDays(tempDate1, 1);
            dispatchState({field : "endDate" , value : tempDate2})
        }
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            setLoaded(1);
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>구매 추가</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body purchase-info-add">
                <div className="info-table-left text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td className="td-select"><SelectCenter onSelectedCenter={handleSelectedCenter} width="w-100"/>
                            </td>
                        </tr>
                        <tr>
                            <td>고객명</td>
                            <td>
                            <input type="text" className="input-readonly" value={name} readOnly/>
                            <Button className="ml-1" onClick={toggleClientSearch} size="sm">검색</Button>
                            </td>
                        </tr>
                        <tr>
                            <td>이용 시작일</td>
                            <td>
                            <DatePicker
                                name = "startDate"
                                selected = {startDate}
                                onChange={handleStartChange}
                                dateFormat="yyyy-MM-dd"
                                className="form-control"
                                locale="ko"
                            />
                            </td>
                        </tr>
                        <tr>
                            <td>상품</td>
                            <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>종목</td>
                                        <td>
                                        <input type="text" className="input-readonly" value={itemName} readOnly/>
                                        <Button className="ml-1" onClick={toggleItemSearch} size="sm">검색</Button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>판매가</td>
                                        <td>
                                        <input type="text" className="input-readonly" value={price1} readOnly/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>구매가</td>
                                        <td>
                                        <input type="text" name="price2" value={price2} onChange={onChange}/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                        <tr>
                            <td>결제 구분</td>
                            <td>
                            <label>
                            <input type="radio" name="paymentType" value='5' onChange={onChange}/>
                             &nbsp;카드(현장)
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="paymentType" value='6' onChange={onChange}/>
                             &nbsp;현금(현장)
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="paymentType" value='9' onChange={onChange}/>
                             &nbsp;PT 회원
                            </label>
                             &nbsp;
                            <label>
                            <input type="radio" name="paymentType" value='2' onChange={onChange}/>
                             &nbsp;현금(계좌)
                            </label>
                            <label>
                            <input type="radio" name="paymentType" value='1' onChange={onChange}/>
                             &nbsp;카드(온라인)
                            </label>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="add" title="등록" buttonClick={() => handleAdd()}/>
                </div>
                </div>
                </div>
            </div>
            {showClientSearch ? 
            <div className="popup purchase-client">
                <SearchClientPopup
                    closePopup={toggleClientSearch}
                    onSubmit = {onClientInfoSubmit}
                />
            </div>
            : null
            }
            {showItemSearch ? 
            <SearchItemPopup
                closePopup={toggleItemSearch}
                onSubmit = {onItemInfoSubmit}
                cid = {selectedCenter}
            />
            : null
            }
            </div>
            </div>
        </main>
    )
}