import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SelectCenter from "./SelectCenter";
import Filter from '../util/Filter';
import { useSelector } from 'react-redux';

var totalCount = 0; 
var clear = false;
var name = '';
var phone = '';

var filterMode = {
    name : true,
    phone : false
}

let storage = {
    nameKeyword : '',
    gubun : 'm'
}

var gubunOption = [{value : 'm', label : '회원'}, {value : 't', label : '강사'}];

function SearchClientPopup(props) {
    const [userInfo, setUserInfo] = useState([]);
    const [selectedCenter, setSelectedCenter ] = useState('');
    const [gubun, setGubun ] = useState({value : 'm', label : '회원'});
    const data = useSelector(state => state.data);

    function getUser(){
        if(clear === true){
            name = ''
        }else{
            name = storage.nameKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/user",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                phoneKeyword : phone,
                gubun : storage.gubun
            }
        }).then(function (response){
            totalCount = response.data.length;
            setUserInfo(response.data);
            clear = false;
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClear(){
        storage.nameKeyword = '';
        storage.phoneKeyword = '';
        clear = true;
        getUser();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        getUser();
    }

    function submitInfo(name, cd){
        props.closePopup();
        props.onSubmit(name, cd, storage.gubun);
    }

    function handleClick(userInfo){
        const name = userInfo.name;
        const cd = userInfo.cd;
        submitInfo(name, cd)
    }

    function handleSelectedCenter(centerId){
        setSelectedCenter(centerId);
    }

    function handleGubunChange(selectedOption){
        setGubun(selectedOption);
        storage.gubun = selectedOption.value;
        getUser();
    }

    useEffect(()=>{
        getUser();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getUser();
        }
    },[selectedCenter]);

    return(
            <div className="searchPopupInner">
            <p>고객명 검색</p>
            <FontAwesomeIcon icon={faTimes} onClick={props.closePopup} className="closeIcon" />
            <SelectCenter 
                onSelectedCenter={handleSelectedCenter}
            />
            <div className="search-popup-container">
            <div style={{width : '200px', float : "left", textAlign : "left"}}>
                <Select 
                    placeholder="회원 구분"
                    value={gubun}
                    onChange={handleGubunChange}
                    options = {gubunOption}
                />
            </div>
            <Filter 
                handleFilter = {handleFilter}  
                handleClear = {handleClear}
                mode = {filterMode}
            />
            </div>
            <Table hover className="scrolltbody">
                <thead>
                <tr>
                    <th>고객명</th>
                    <th>센터명</th>
                    <th>전화번호</th>
                </tr>
                </thead>
                <tbody>
                {(totalCount === 0) &&
                        <tr>
                            <td colSpan='20' style={{width:'540px'}}>
                                데이터가 없습니다.
                            </td>
                        </tr>
                }
                {userInfo.map((userInfo, index) => {
                    const { name, phone, center, location} = userInfo;
                    return(
                        <tr key={index} onClick={()=>handleClick(userInfo)}>
                            <td>{name}</td>
                            <td>{center + " " + location}</td>
                            <td>{phone}</td>
                        </tr>
                    )
                })}
                {Number(totalCount) > 100 &&
                    <tr>
                    <td colSpan="20" className="bg-light text-center">...</td>
                    </tr>
                }

                </tbody>
            </Table>
            </div>

    )
}

export default SearchClientPopup;
