import React from "react";
import '../components/Main.css';
import CountUp from 'react-countup';

export default function HomeCard({ cardList, centerId }) {
    if(centerId === ''){
        centerId = 1;
    }
    return (
        <div className="card">
            <a href={"visit/"+centerId+"/"+cardList.sdate} className="card-link">
            <div className="card-left" style={{backgroundColor : cardList.color}}>
                <span className="card-num">
                    <CountUp end={cardList.num}/></span>
                <p className="card-title">{cardList.title}</p>
            </div>
            <div className="card-right" style={{backgroundColor : cardList.colorLight}}>
            </div>
            </a>
        </div>
    )
}