import React, { useState, useEffect } from "react";
import axios from 'axios';
import styled from "styled-components";
import logo from '../img/logo.png';
import { useSelector } from 'react-redux';

const MainContainer = styled.div`
    background: #1C8EF9 !important;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  
  & h3 {
      text-align:center;
  }
  & .img-wrap {
      text-align:center;
  }
  & .auth-inner {
    width: 450px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
    padding: 40px 55px 45px 55px;
    border-radius: 15px;
    transition: all .3s;
  }
  
  & .auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
  }
  
  & .auth-wrapper h3 {
    text-align: center;
    margin: 0;
    line-height: 1;
    padding-bottom: 20px;
  }
  
  & .custom-control-label {
    font-weight: 400;
  }

  & .btn-login {
    padding: 12px 0;
  }
`
let refresh_token = '';
let access_token = '';

const Login = () => {
    const [id, setId] = useState('');
    const [pw, setPw] = useState('');
    const [remeber, setRemember] = useState(false);
    const data = useSelector(state => state.data);

    function handleLogin(){
      if(remeber){
        localStorage.setItem('remeber_id', id);
      }else{
        localStorage.setItem('remeber_id', '');
      }
      
      axios({
        url : data.url + "/slim/token/createAdminRefreshToken",
        method : "post",
        data : {
          sid : 'smartgym',
          phone : id,
          pass : pw
        }
      }).then(function(response) {
        refresh_token = response.data.refresh_token;
        localStorage.setItem('refresh_token', refresh_token);
        
        axios({
          url : data.url + "/slim/token/createAccessToken",
          method : "post",
          data : {
            sid : "smartgym",
            refresh_token : refresh_token
          }
        }).then(function (response){
          access_token = response.data.access_token;
          localStorage.setItem('access_token', access_token);
          axios({
            url : data.url + "/slim/token/checkAccessToken",
            method : "post",
            data : {
              sid : "smartgym",
              access_token : access_token
            }
          }).then(function (response){
            if(response.data.ret === "Y"){
              window.location.href="/home";
            }else{
              alert("로그인 인증 실패")
            }
          } )
        })
        
      }).catch(error => console.log(error) )
    }

    function handleIdChange(event){
      setId(event.target.value);
    }

    function handlePwChange(event){
      setPw(event.target.value);
    }

    function handelRemeberChange(event){
      setRemember(!remeber);
    }

    useEffect(()=>{
      setId(localStorage.getItem('remeber_id'));
      if(localStorage.getItem('remeber_id') !== '' && localStorage.getItem('remeber_id') !== null){
        setRemember(true);
      }
    },[]);

        return (
            <MainContainer className="auth-wrapper">
                <div className="auth-inner">
                    <div className="img-wrap">
                      <img src={logo} alt="logo" />
                    </div>
                    <h3>스마트 패스 관리자</h3>

                    <div className="form-group">
                        <label>아이디</label>
                        <input type="text" value={id} onChange={handleIdChange} className="form-control" placeholder="아이디를 입력해주세요" />
                    </div>

                    <div className="form-group">
                        <label>비밀번호</label>
                        <input type="password" value={pw} onChange={handlePwChange} className="form-control" placeholder="비밀번호를 입력해주세요" />
                    </div>

                    <div className="form-group">
                        <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="customCheck1" onChange={handelRemeberChange} checked={remeber} />
                            <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                        </div>
                    </div>

                    <button onClick={handleLogin} className="btn btn-primary btn-block btn-login">로그인</button>
                </div>
            </MainContainer>
        );
}

export default Login;