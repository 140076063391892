import React from 'react';
import DaumPostcode from 'react-daum-postcode';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export default function DaumPostcodePopup(props){

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = ''; 
        
        if (data.addressType === 'R') {
          if (data.bname !== '') {
            extraAddress += data.bname;
          }
          if (data.buildingName !== '') {
            extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
          }
          fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
        }
        props.selectAddress(fullAddress);
    }
    
  return (
    <div className="popup postcode">
        <div className="popupInner">
            <div className="handle">
            <h3>주소검색</h3>
            <FontAwesomeIcon icon={faTimes} onClick={props.closePopup} className="closeIcon" />
            </div>
            <DaumPostcode
              onComplete={handleComplete}
            />
        </div>
    </div>
  );
}