import React from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Privacy from "./components/Privacy";
import PrivacyLive from "./components/PrivacyLive";
import SmartKey from './components/Smartkey';
import Visit from './components/Visit';
import Client from './components/Client';
import ClientInfo from './components/ClientInfo';
import ClientInfoEdit from './components/ClientInfoEdit';
import ClientInfoAdd from './components/ClientInfoAdd';
import Center from './components/Center';
import CenterInfo from './components/CenterInfo';
import CenterInfoAdd from './components/CenterInfoAdd';
import CenterInfoEdit from './components/CenterInfoEdit';
import Purchase from './components/Purchase';
import PurchaseInfo from './components/PurchaseInfo';
import PurchaseInfoAdd from './components/PurchaseInfoAdd';
import PurchaseInfoEdit from './components/PurchaseInfoEdit';
import Teacher from './components/Teacher';
import TeacherInfo from './components/TeacherInfo';
import TeacherInfoEdit from './components/TeacherInfoEdit';
import TeacherInfoAdd from './components/TeacherInfoAdd';
import Item from './components/Item';
import Gate from './components/Gate';
import styled from 'styled-components';
import Home from './components/Home';
import { Provider } from 'react-redux';
import store from './store'
import { useDispatch } from 'react-redux'

const MainContainer = styled.div`

`

function ReduxComponent() {
  const dispatch = useDispatch();
  dispatch({type:'GET', name:"URL"});
  return (
    <></>
  )
}

function App() {

  return (
  <Provider store={store}>
  <MainContainer className="App">
  <ReduxComponent></ReduxComponent>
  <Router>
          <Switch>
          <Route exact path='/' component={Login}/>
          <Route exact path='/login' component={Login}/>
            {/* {isLogin === true &&
            <> */}
            <Route exact path='/home' component={Home}/>
            <Route exact path='/smartkey' component={SmartKey}/>
            <Route exact path='/visit/:center/:sdate' component={Visit}/>
            <Route exact path='/client' component={Client}/>
            <Route exact path='/clientInfo/:mcd' component={ClientInfo}/>
            <Route exact path='/clientInfoEdit/:mcd' component={ClientInfoEdit}/>
            <Route exact path='/clientInfoAdd' component={ClientInfoAdd}/>
            <Route exact path='/center' component={Center}/>
            <Route exact path='/centerInfo/:cid' component={CenterInfo}/>
            <Route exact path='/centerInfoAdd' component={CenterInfoAdd}/>
            <Route exact path='/centerInfoEdit/:cid' component={CenterInfoEdit}/>
            <Route exact path='/purchase' component={Purchase}/>
            <Route exact path='/purchaseInfo/:pcd' component={PurchaseInfo}/>
            <Route exact path='/purchaseInfoAdd' component={PurchaseInfoAdd}/>
            <Route exact path='/purchaseInfoEdit/:pcd' component={PurchaseInfoEdit}/>
            <Route exact path='/teacher' component={Teacher}/>
            <Route exact path='/teacherInfo/:tcd' component={TeacherInfo}/>
            <Route exact path='/teacherInfoEdit/:tcd' component={TeacherInfoEdit}/>
            <Route exact path='/teacherInfoAdd' component={TeacherInfoAdd}/>
            <Route exact path='/item' component={Item}/>
            <Route exact path='/gate' component={Gate}/>
            {/* </>
            } */}
          <Route exact path='/privacy/:cid' component={Privacy}/>
          <Route exact path='/privacyLive' component={PrivacyLive}/>
          </Switch>
  </Router>
  </MainContainer>
  </Provider>
  );
}

export default App;