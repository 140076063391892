import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import DecodeData from '../util/DecodeData';
import LoginFail from '../util/LoginFail';
import format from 'date-fns/format';
import Pagination from "react-js-pagination";
import pagiarray from 'paginate-array';
import SelectCenter from "../util/SelectCenter";
import Filter from '../util/Filter';
import { subDays, subMonths } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

var activePage = 1;
var totalCount = 0; //총회원수
const pageSize = 10;
var showSelect = false;
var centerName = '';
var visitData = '';
var clear = false;
var name = '';
var phone = '';

var filterMode = {
    name : true,
    phone : true
}

let storage = {
    nameKeyword : '',
    phoneKeyword : '',
}

let loaded = false;

export default function Visit({match}) {
    const now = new Date();
    const month = subMonths(now, 1);
    const monthdate = new Date(month.getFullYear(), month.getMonth(), month.getDate(), 0, 0, 0);
    let sdate = '';

    if(match.params.sdate === "month"){
        sdate = monthdate;
    }else{
        // sdate = new Date(match.params.sdate);
        sdate = moment(match.params.sdate).toDate();
    }
    const center = match.params.center
    var centerId = '';
    if(center === "center"){
        showSelect = true;
    }else if(center === "1"){
        
    }else{
        centerId = center;
    }
    const [selectedCenter, setSelectedCenter ] = useState(centerId);
    const [startDate, setStartDate] = useState(sdate);
    const [endDate, setEndDate] = useState(new Date());
    const [visit, setVisit] = useState([]);
    const data = useSelector(state => state.data);
    const dispatch = useDispatch();

    function handleSelectedCenter(cid){
        setSelectedCenter(cid);
    }

    function handlePageChange(changepage) {
        activePage = changepage;
        const page = activePage;
        const pageCurrent = pagiarray(visitData,page,pageSize);
        
        totalCount = visitData.length;

        setVisit(pageCurrent.data);
    }
    
    function handleStartChange(date) {
        setStartDate(date)
    }

    function handleEndChange(date) {
        setEndDate(date);
    }
    
    function getVisit(){
        const formatStartDate = format(startDate, 'yyyy-MM-dd');
        const formatEndDate = format(endDate, 'yyyy-MM-dd')
        const sdate = formatStartDate + " 00:00:00";
        const edate = formatEndDate + " 23:59:59";

        if(clear === true){
            name = ''
            phone = '';
        }else{
            name = storage.nameKeyword;
            phone = storage.phoneKeyword;
        }
        var temp_cid = '';
        if(data.cid !== 'root'){
            temp_cid = data.cid
        }else{
            temp_cid = selectedCenter
        }

        axios({
            url : data.url + "/slim/visit",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : temp_cid,
                nameKeyword : name,
                phoneKeyword : phone,
                startDate : sdate,
                endDate : edate
            }
        }).then(function (res){
            visitData = res.data;
            const page = activePage;
            const pageCurrent = pagiarray(visitData,page,pageSize);
            
            totalCount = visitData.length;

            setVisit(pageCurrent.data);
            loaded = true;
        }).catch(function(error){
            console.log(error);
        })
    }

    function getCenterName(){
        axios({
            url : data.url + "/slim/center_name",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : centerId,
            }
        }).then(function (res){
            if(res.data.length === 0){
                centerName = "전 지점"
            }else{
                centerName = res.data[0].center + " " +res.data[0].location;
            }
        }).catch(function(error){
            console.log(error);
        })
    }

    function handleClear(){
        storage.nameKeyword = '';
        storage.phoneKeyword = '';
        setStartDate(monthdate);
        setEndDate(new Date());
        clear = true;
        activePage = 1;
        getVisit();
    }

    function handleFilter(filterData){
        storage.nameKeyword = filterData.nameKeyword;
        storage.phoneKeyword = filterData.phoneKeyword;
        activePage = 1;
        clear = false;
        getVisit();
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            const decodeResult = await DecodeData(data.url);
            dispatch({type:'SET', name:"CID", value:decodeResult.cid});
            dispatch({type:'SET', name:"LEVEL", value:decodeResult.level});
            getVisit();
        }else{
            LoginFail();
        }
    }

    useEffect(()=>{
        main();
        getCenterName();
    },[]);

    useEffect(()=>{
        if(data.cid === "root"){
            getVisit();
            activePage = 1;
        }
    },[selectedCenter]);

    useEffect(()=>{
        if(loaded){
            getVisit();
            activePage = 1;
        }
    },[startDate, endDate]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner visit">
            <h3>방문 관리</h3>
            <div className="content-component">
                <div className="content-header">
                    <div></div>
                    {showSelect ?
                        <SelectCenter 
                        onSelectedCenter={handleSelectedCenter}
                        />
                        : <h3 className="center-name">{centerName}</h3>
                    }
                </div>
                <div className="content-body">
                    <div className="table-header">
                        <div className="search-left-container">
                        <span className="mr-1 white-title">날짜&nbsp;</span>
                        <DatePicker
                            selected = {startDate}
                            onChange={handleStartChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            locale="ko"
                        />
                        <span>&nbsp;~&nbsp;</span>
                        <DatePicker
                            selected = {endDate}
                            onChange={handleEndChange}
                            dateFormat="yyyy-MM-dd"
                            className="form-control"
                            style={{width:'100px'}}
                            locale="ko"
                        />
                        </div>
                        <Filter 
                            handleFilter = {handleFilter}   
                            handleClear = {handleClear}
                            mode = {filterMode}
                        />
                    </div>
                    <div className="table-body">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th style={{width:'300px'}}>출입일자</th>
                                <th style={{width:'160px'}}>고객명</th>
                                <th style={{width:'230px'}}>전화번호</th>
                                <th style={{width:'200px'}}>센터명</th>
                                <th style={{width:'160px'}}>장치번호</th>
                                <th style={{width:'150px'}}>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                        {(totalCount === 0) &&
                                <tr>
                                    <td colSpan='20'>
                                        데이터가 없습니다.
                                    </td>
                                </tr>
                        }
                        {visit.map(( visit, index ) => {
                            const { regdate, name, phone, doorId, center, location } = visit;
                            return(
                                <tr key={index} >
                                    <td>{regdate}</td>
                                    <td>
                                        { (name === '' || name === null ) ? 'staff' : name}
                                        </td>
                                    <td>{phone}</td>
                                    <td>{center + " " +location}</td>
                                    <td>{doorId}</td>
                                    <td></td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </Table>
                    </div>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={pageSize}
                        pageRangeDisplayed={5}
                        totalItemsCount={totalCount}
                        itemClass="page-item"
                        linkClass="page-link"
                        onChange={handlePageChange}
					/>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}