import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Table } from 'react-bootstrap';
import './Main.css';
import TopNav from './TopNav';
import MainNav from './MainNav';
import LoginCheck from '../util/LoginCheck';
import ButtonStyle from '../util/ButtonStyle';
import { confirmAlert } from 'react-confirm-alert';
import { useSelector } from 'react-redux';

var managerData = [];

export default function CenterInfo({match}) {
    const cid = match.params.cid;
    const [centerInfo, setCenterInfo] = useState([]);
    const data = useSelector(state => state.data);

    function getCenterDetailInfo(){
        axios({
            url : data.url + "/slim/center_detail_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : cid
            }
        }).then(function (res){
            var managerCode = res.data[0].manager;
            var temp = res.data[0];

            var num1 = res.data[0].phone.substring(0,3);
            var num2 = res.data[0].phone.substring(3,6);
            var num3 = res.data[0].phone.substring(6,10);
            temp["formatPhone"] = num1 + '-' + num2 + "-" + num3;

            temp["manager1"] = '';
            temp["manager2"] = '';
            
            axios({
                url : data.url + "/slim/manager_name",
                method : "post",
                data : {
                    sid : 'smartgym',
                    code : managerCode
                }
            }).then(function (res){
                managerData = res.data;
                
                if(managerData[0]){
                    temp["manager1"] = managerData[0].name;
                }
                if(managerData[1]){
                    temp["manager2"] = managerData[1].name;
                }
                setCenterInfo(temp);
            }).catch(function(error){
                console.log(error);
            })
        }).catch(function(error){
            console.log(error);
        })
    }

    function deleteCenter(){
        axios({
            url : data.url + "/slim/delete_center_info",
            method : "post",
            data : {
                sid : 'smartgym',
                cid : cid
            }
        }).then(function (response){
            alert("삭제되었습니다.");
            window.location.href = '/center';
        }).catch(function (error){
            console.log(error);
        })
    }

    function moveList(){
        window.location.href = '/center';
    }

    function moveEdit(){
        window.location.href = '/centerInfoEdit/'+cid;
    }

    function handleDelete() {
        confirmAlert({
            title: '삭제 확인',
            message: centerInfo.center +" "+ centerInfo.location + "의 정보를 삭제하시겠습니까?",
            buttons: [
              {
                label: 'Yes',
                onClick: () => deleteCenter()
              },
              {
                label: 'No'
              }
            ]
          });
    }

    async function main() {
        const result = await LoginCheck(data.url);
        if(result === "Y"){
            getCenterDetailInfo(); 
        }
    }

    useEffect(()=>{
        main();
    },[]);

    return (
        <main className="main-container">
            <TopNav />
            <div className="content-wrapper">
            <MainNav />
            <div className="content-inner">
            <h3>가맹점 상세정보</h3>
            <div className="content-component">
                <div className="content-header">
                </div>
                <div className="content-body center-info">
                <div className="info-table-left text-left">
                <Table bordered>
                    <tbody>
                        <tr>
                            <td>가맹점</td>
                            <td>{centerInfo.center +" "+ centerInfo.location}</td>
                        </tr>
                        <tr>
                            <td>전화번호</td>
                            <td>{centerInfo.formatPhone}</td>
                        </tr>
                        <tr>
                            <td>주소</td>
                            <td>{centerInfo.address}</td>
                        </tr>
                        <tr>
                            <td>등록일</td>
                            <td>
                                {centerInfo.regdate}
                            </td>
                        </tr>
                        <tr>
                            <td>담당자</td>
                            <td>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>주 담당자</td>
                                        <td>
                                            {centerInfo.manager1}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>부 담당자</td>
                                        <td>
                                            {centerInfo.manager2}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <div className="button-container">
                    <ButtonStyle mode="list" title="목록" buttonClick={() => moveList()}/>
                    <ButtonStyle mode="edit" title="수정" buttonClick={() => moveEdit()}/>
                    <ButtonStyle mode="delete" title="삭제" buttonClick={() => handleDelete()}/>
                </div>
                </div>
                </div>
            </div>
            </div>
            </div>
        </main>
    )
}