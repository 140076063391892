import React, { useState } from "react";
import {Button, Form} from 'react-bootstrap';

let filterData = {
    nameKeyword : '',
    phoneKeyword : ''
}

export default function Filter({handleFilter, handleClear, mode }) {
    const [nameKeyword, setNameKeyword ] = useState('');
    const [phoneKeyword, setPhoneKeyword] = useState('');

    function handleNameKeywordChange(event){
        setNameKeyword(event.target.value);
        filterData.nameKeyword = event.target.value;
    }

    function handlePhoneKeywordChange(event){
        const re = /^[0-9\b]+$/;
        if (event.target.value === '' || re.test(event.target.value)) {
            setPhoneKeyword(event.target.value);
            filterData.phoneKeyword = event.target.value;
        }
    }

    function Clear(){
        filterData.nameKeyword = '';
        filterData.phoneKeyword = '';
        setNameKeyword('');
        setPhoneKeyword('');
        handleClear();
    }

    return (
        <div className="search-rigth-container">
        {mode.name ? 
            <Form.Control type="text" id="searchName" style={{marginRight:'10px'}} placeholder="이름" value={nameKeyword} onChange={handleNameKeywordChange}/>
        : null
        }
        {mode.phone ? 
            <Form.Control type="text" id="searchPhone" style={{marginRight:'10px'}} placeholder="전화번호" value={phoneKeyword} onChange={handlePhoneKeywordChange} maxLength="11"/>
        : null
        }
        <Button size="sm" onClick={()=>handleFilter(filterData)}>검색</Button>
        <Button variant="info" size="sm" className="ml-1" onClick={Clear}>초기화</Button>
        </div>
    )
}